export class Phlebotomy {
  _id: string;
  existsRecordId: string;
  specimenId: string;
  labId: string;
  containerName: string;
  sampleType: string;
  tubeName: string;
  required: boolean;
  autoReceived: boolean;
  isDefaultSample: boolean;
  containerCount: number;
  workflowSteps: string;
  lab: string;
  department: string;
  registrationLocation: string;
  outsource: boolean;
  outsourceLab: string;
  outsourceStatus: string;
  stage: string;
  goToStep: string;
  action: string;
  sampleCollection: string;
  comments: string;
  actionDate: Date;
  actionBy: string;
  sampleCustody: string;
  methodCollection: string;
  sharedSample: string;
  shareSequence: string;
  sharedDepartment: string;
  containerImage: string;
  tatInMin: string;
  testList: string;
  labList: string;
  departmentList: string;
  urgent: boolean;
  vip: boolean;
  status: string;
  registrationStatus: string;
  customerType: string;
  companyCode: string;
  environment: string;
  enteredBy: string;
  dateCreation: Date;
  dateOfEntry: Date;
  lastUpdated: Date;

  static types = {
    _id: 'string',
    existsRecordId: 'string',
    specimenId: 'string',
    labId: 'string',
    containerName: 'string',
    sampleType: 'string',
    tubeName: 'string',
    required: 'boolean',
    autoReceived: 'boolean',
    isDefaultSample: 'boolean',
    containerCount: 'number',
    workflowSteps: 'string',
    lab: 'string',
    department: 'string',
    registrationLocation: 'string',
    outsource: 'boolean',
    outsourceLab: 'string',
    outsourceStatus: 'string',
    stage: 'string',
    goToStep: 'string',
    action: 'string',
    sampleCollection: 'string',
    comments: 'string',
    actionDate: 'Date',
    actionBy: 'string',
    sampleCustody: 'string',
    methodCollection: 'string',
    sharedSample: 'string',
    shareSequence: 'string',
    sharedDepartment: 'string',
    containerImage: 'string',
    tatInMin: 'string',
    testList: 'string',
    labList: 'string',
    departmentList: 'string',
    urgent: 'boolean',
    vip: 'boolean',
    status: 'string',
    registrationStatus: 'string',
    customerType: 'string',
    companyCode: 'string',
    environment: 'string',
    enteredBy: 'string',
    dateCreation: 'Date',
    dateOfEntry: 'Date',
    lastUpdated: 'Date',
  };

  constructor(rawData: { [key in string]: any }) {
    this._id = rawData._id;
    this.existsRecordId = rawData.existsRecordId;
    this.specimenId = rawData.specimenId;
    this.labId = rawData.labId;
    this.autoReceived = rawData.autoReceived;
    this.required = rawData.required;
    this.containerName = rawData.containerName;
    this.sampleType = rawData.sampleType;
    this.tubeName = rawData.tubeName;
    this.isDefaultSample = rawData.isDefaultSample;
    this.containerCount = rawData.containerCount;
    this.workflowSteps = rawData.workflowSteps;
    this.department = rawData.department;
    this.registrationLocation = rawData.registrationLocation;
    this.lab = rawData.lab;
    this.outsource = rawData.outsource;
    this.outsourceLab = rawData.outsourceLab;
    this.outsourceStatus = rawData.outsourceStatus;
    this.stage = rawData.stage;
    this.goToStep = rawData.goToStep;
    this.action = rawData.action;
    this.sampleCollection = rawData.sampleCollection;
    this.comments = rawData.comments;
    this.actionDate = rawData.actionDate;
    this.actionBy = rawData.actionBy;
    this.sampleCustody = rawData.sampleCustody;
    this.methodCollection = rawData.methodCollection;
    this.sharedSample = rawData.sharedSample;
    this.shareSequence = rawData.shareSequence;
    this.sharedDepartment = rawData.sharedDepartment;
    this.tatInMin = rawData.tatInMin;
    this.testList = rawData.testList;
    this.labList = rawData.labList;
    this.departmentList = rawData.departmentList;
    this.urgent = rawData.urgent;
    this.vip = rawData.vip;
    this.containerImage = rawData.containerImage;
    this.status = rawData.status;
    this.registrationStatus = rawData.registrationStatus;
    this.customerType = rawData.customerType;
    this.companyCode = rawData.customerType;
    this.environment = rawData.environment;
    this.enteredBy = rawData.enteredBy;
    this.dateCreation = rawData.dateCreation;
    this.dateOfEntry = rawData.dateOfEntry;
    this.lastUpdated = rawData.lastUpdated;
  }
}
