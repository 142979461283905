import { stores } from '@/stores';
export const startup = async () => {
  if (stores.loginStore.login?.validationLevel) {
    stores.panelApprovalStore.panelApprovalService.listPanelApproval({
      approvalStatus: 'Pending',
      validationLevel: stores.loginStore.login?.validationLevel,
    });
  } else {
    console.log('User validation level not found!');
    throw new Error('User validation level not found!');
  }
};
