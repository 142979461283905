import React, { useEffect, useState } from 'react';
import { Container } from 'reactstrap';
import {
  Form,
  AutoCompleteFilterMutiSelectMultiFieldsDisplay,
} from '@/library/components';
import { lookupItems } from '@/library/utils';
import { useStores } from '@/stores';
import { Table } from 'react-bootstrap';
import _ from 'lodash';

interface MiscChargeEntry {
  code: string;
  value: string;
  amount: string;
  __typename?: string;
}

interface ModalMiscChargesModifyProps {
  show?: boolean;
  arrValues?: MiscChargeEntry[];
  id?: string;
  onClick: (arrValues: MiscChargeEntry[], id: string) => void;
  onClose: () => void;
}

export const ModalMiscChargesModify = (props: ModalMiscChargesModifyProps) => {
  const { routerStore } = useStores();
  const [showModal, setShowModal] = useState<boolean | undefined>(props.show);
  const [values, setValues] = useState<any>(props.arrValues || []);

  useEffect(() => {
    setShowModal(props.show);
    setValues(props.arrValues || []);
  }, [props]);

  const handleAmountChange = (amount: number, index: number) => {
    const updatedValues = values.map((item, idx) =>
      idx === index ? { ...item, amount } : item,
    );

    console.log(updatedValues);
    setValues(updatedValues);
  };

  const handleSelect = (item: MiscChargeEntry) => {
    const alreadySelected = values.some(
      selectedItem => selectedItem.code === item.code,
    );

    let updatedValues;

    if (alreadySelected) {
      // If already selected, deselect it
      updatedValues = values.filter(
        selectedItem => selectedItem.code !== item.code,
      );
    } else {
      // If not selected, add it to the values
      updatedValues = [...values, item];
    }

    setValues(updatedValues);
  };

  return (
    <Container>
      {showModal && (
        <>
          <div className='justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none'>
            <div className='relative w-auto my-6 mx-auto max-w-3xl'>
              <div className='border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none'>
                <div className='flex flex-col justify-between p-2 border-b border-solid border-gray-300 rounded-t'>
                  <h4 className='font-semibold text-lg'>Update Misc Charges</h4>
                </div>

                <div className='relative ml-24 mr-24 p-2 flex-auto'>
                  <div className='flex flex-row gap-4'>
                    <AutoCompleteFilterMutiSelectMultiFieldsDisplay
                      placeholder='Misc Charges'
                      data={{
                        list: lookupItems(
                          routerStore.lookupItems,
                          'PATIENT VISIT - MISC_CHARGES',
                        )?.map((item, index) => ({
                          ...item,
                          _id: index.toString(),
                        })),
                        selected: values,
                        displayKey: ['code', 'value'],
                      }}
                      uniqueField='code'
                      onUpdate={item => {}}
                      onSelect={item => handleSelect(item)}
                    />
                  </div>
                  <div className='flex flex-row gap-2 mt-2 flex-wrap'>
                    <Table striped bordered>
                      <thead>
                        <tr className='p-0 text-xs'>
                          <th className='text-white sticky left-0 z-10'>
                            MISC CHARGES
                          </th>
                          <th className='text-white'>AMOUNT</th>
                        </tr>
                      </thead>
                      <tbody className='text-xs'>
                        {values?.map((item, index) => (
                          <tr key={item.code}>
                            <td className='sticky left-0'>
                              {item?.value + ' - ' + item?.code}
                            </td>
                            <td>
                              <Form.Input
                                style={{ height: 30 }}
                                label=''
                                type='number'
                                placeholder='Amount'
                                value={item.amount}
                                onChange={e => handleAmountChange(e, index)}
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </div>

                <div className='flex items-center justify-end p-2 border-t border-solid border-gray-300 rounded-b'>
                  <button
                    className='text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1'
                    type='button'
                    style={{ transition: 'all .15s ease' }}
                    onClick={() => {
                      props.onClose();
                      setShowModal(false);
                    }}
                  >
                    Close
                  </button>
                  <button
                    className='bg-green-500 text-white active:bg-green-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1'
                    type='button'
                    style={{ transition: 'all .15s ease' }}
                    onClick={() => {
                      setShowModal(false);
                      props.onClick(values, props.id || '');
                    }}
                  >
                    Update
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className='opacity-25 fixed inset-0 z-40 bg-black'></div>
        </>
      )}
    </Container>
  );
};
