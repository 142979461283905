import { makeObservable, action, observable, computed } from 'mobx';
import { BookingChart } from '../models';
import dayjs from 'dayjs';
// import { DeginisationService } from '../services';

export class BookingChartStore {
  listBookingChart!: BookingChart[];
  listBookingChartCount = 0;
  bookingChart!: BookingChart;

  constructor() {
    this.listBookingChart = [];
    this.bookingChart = new BookingChart({});
    this.reset();
    makeObservable<BookingChartStore, any>(this, {
      listBookingChart: observable,
      listBookingChartCount: observable,

      BookingChartService: computed,
      fetchListBookingChart: action,
      updateBookingChart: action,
      reset: action,
    });
  }

  reset() {
    this.bookingChart = new BookingChart({
      startTime: '06:00 am',
      pickupTime: '07:00 pm',
      dropTime: '07:00 pm',
      status: 'A',
      dateCreation: new Date(),
      dateActive: new Date(),
      dateExpire: new Date(
        dayjs(new Date()).add(365, 'days').format('YYYY-MM-DD'),
      ),
    });
    this.listBookingChart = [];
    this.listBookingChartCount = 0;
  }

  get BookingChartService() {
    return;
  }

  fetchListBookingChart(page?, limit?) {
    // this.DeginisationService.listDeginisation(page, limit);
  }

  updateBookingChart = (chart: BookingChart) => {
    this.bookingChart = chart;
  };
}
