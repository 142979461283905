import * as ModelsLab from '@/features/master/labs/models';
import * as ModelsDepartment from '@/features/master/department/models';
import * as ModelsRole from '@/features/settings/role/models';
export class Users {
  _id: string;
  existsVersionId: string;
  existsRecordId: string;
  defaultLab: string;
  defaultDepartment: string;
  userGroup: string;
  userModule: string;
  userModuleCategory: string; // Added userModuleCategory
  userId: string;
  fullName: string;
  empCode: string;
  reportingTo: string;
  deginisation: string;
  userDegree: string;
  role: ModelsRole.Role[];
  password: string;
  lab: ModelsLab.Labs[];
  department: ModelsDepartment.Department[];
  corporateClient: Array<any>;
  registrationLocation: Array<any>;
  doctors: Array<any>;
  mobileNo: string;
  contactNo: string;
  email: string;
  signature: any;
  webPushTokenFcm: string; // Added webPushTokenFcm
  picture: any;
  validationLevel: number;
  dateOfBirth: Date;
  marriageAnniversary: Date;
  exipreDate: Date;
  expireDays: number;
  confidential: boolean;
  confirguration: boolean;
  systemInfo: {
    ipAddress?: any[];
    workstation?: any[];
    accessInfo: {
      mobile: boolean;
      desktop: boolean;
    };
  };
  passwordHistory: Array<string>; // Added passwordHistory
  shortcutMenu: any; // Added shortcutMenu
  sessionAllowedCount: number; // Added sessionAllowedCount
  accessToken: string; // Added accessToken
  noticeBoard: string; // Added noticeBoard
  dateCreation: Date;
  dateActive: Date;
  createdBy: string;
  companyCode: string;
  status: string;
  version: number;
  environment: string;
  passChanged: boolean;
  dateOfEntry: Date;
  lastUpdated: Date;

  static types = {
    _id: 'string',
    existsVersionId: 'string',
    existsRecordId: 'string',
    defaultLab: 'string',
    defaultDepartment: 'string',
    userGroup: 'string',
    userModule: 'string',
    userModuleCategory: 'string', // Added userModuleCategory
    userId: 'string',
    fullName: 'string',
    empCode: 'string',
    reportingTo: 'string',
    deginisation: 'string',
    userDegree: 'string',
    passwordHistory: 'Array', // Added passwordHistory
    shortcutMenu: 'object', // Added shortcutMenu
    sessionAllowedCount: 'number', // Added sessionAllowedCount
    accessToken: 'string', // Added accessToken
    noticeBoard: 'string', // Added noticeBoard
    role: 'Array',
    password: 'string',
    lab: 'Array',
    department: 'Array',
    corporateClient: 'Array',
    registrationLocation: 'Array',
    doctors: 'Array',
    mobileNo: 'string',
    contactNo: 'string',
    email: 'string',
    signature: 'any',
    picture: 'any',
    validationLevel: 'number',
    dateOfBirth: 'Date',
    marriageAnniversary: 'Date',
    exipreDate: 'Date',
    expireDays: 'number',
    confidential: 'boolean',
    confirguration: 'boolean',
    systemInfo: 'object',
    dateCreation: 'Date',
    dateActive: 'Date',
    createdBy: 'string',
    companyCode: 'string',
    status: 'string',
    version: 'number',
    environment: 'string',
    passChanged: 'boolean',
    dateOfEntry: 'Date',
    lastUpdated: 'Date',
  };

  constructor(rawData: { [key in string]: any }) {
    this._id = rawData._id;
    this.existsVersionId = rawData.existsVersionId;
    this.existsRecordId = rawData.existsRecordId;
    this.defaultLab = rawData.defaultLab;
    this.defaultDepartment = rawData.defaultDepartment;
    this.userGroup = rawData.userGroup;
    this.userModule = rawData.userModule;
    this.userModuleCategory = rawData.userModuleCategory; // Added userModuleCategory;
    this.userId = rawData.userId;
    this.fullName = rawData.fullName;
    this.empCode = rawData.empCode;
    this.reportingTo = rawData.reportingTo;
    this.deginisation = rawData.deginisation;
    this.userDegree = rawData.userDegree;
    this.role = rawData.role;
    this.password = rawData.password;
    this.webPushTokenFcm = rawData.webPushTokenFcm; // Added webPushTokenFcm
    this.lab = rawData.lab;
    this.department = rawData.department;
    this.corporateClient = rawData.corporateClient;
    this.registrationLocation = rawData.registrationLocation;
    this.doctors = rawData.doctors;
    this.mobileNo = rawData.mobileNo;
    this.contactNo = rawData.contactNo;
    this.email = rawData.email;
    this.signature = rawData.signature;
    this.picture = rawData.picture;
    this.validationLevel = rawData.validationLevel;
    this.dateOfBirth = rawData.dateOfBirth;
    this.marriageAnniversary = rawData.marriageAnniversary;
    this.exipreDate = rawData.exipreDate;
    this.expireDays = rawData.expireDays;
    this.confidential = rawData.confidential;
    this.confirguration = rawData.confirguration;
    this.passwordHistory = rawData.passwordHistory; // Added passwordHistory
    this.shortcutMenu = rawData.shortcutMenu; // Added shortcutMenu
    this.sessionAllowedCount = rawData.sessionAllowedCount; // Added sessionAllowedCount
    this.accessToken = rawData.accessToken; // Added accessToken
    this.noticeBoard = rawData.noticeBoard; // Added noticeBoard
    this.systemInfo = rawData.systemInfo;
    this.dateCreation = rawData.dateCreation;
    this.dateActive = rawData.dateActive;
    this.createdBy = rawData.createdBy;
    this.companyCode = rawData.companyCode;
    this.status = rawData.status;
    this.version = rawData.version;
    this.environment = rawData.environment;
    this.passChanged = rawData.passChanged;
    this.dateOfEntry = rawData.dateOfEntry;
    this.lastUpdated = rawData.lastUpdated;
  }
}

export class SelectedItems {
  roles: any[];
  labs: any[];
  department: any[];
  corporateClient: any[];
  registrationLocation: any[];
  doctors: any[];
  constructor(rawData: { [key in string]: any }) {
    this.roles = rawData.roles;
    this.labs = rawData.labs;
    this.department = rawData.department;
    this.corporateClient = rawData.corporateClient;
    this.registrationLocation = rawData.registrationLocation;
    this.doctors = rawData.doctors;
  }
}
