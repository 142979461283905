import { makeObservable, action, observable, computed } from 'mobx';
import { Phlebotomy } from '../models';

export class PhlebotomyStore {
  phlebotomy!: Phlebotomy;
  listPhlebotomy: Phlebotomy[];
  listPhlebotomyCount: number;

  constructor() {
    this.listPhlebotomy = [];
    this.listPhlebotomyCount = 0;
    this.reset();
    makeObservable<PhlebotomyStore, any>(this, {
      phlebotomy: observable,
      listPhlebotomy: observable,
      listPhlebotomyCount: observable,

      phlebotomyService: computed,
      fetchPhlebotomy: action,
      updatePhlebotomy: action,
      reset: action,
    });
  }

  get phlebotomyService() {
    return;
  }

  reset() {
    this.phlebotomy = new Phlebotomy({});
    this.listPhlebotomy = [];
    this.listPhlebotomyCount = 0;
    this.phlebotomy = {
      ...this.phlebotomy,
      actionDate: new Date(),
      dateCreation: new Date(),
    };
  }

  fetchPhlebotomy(page?, limit?) {}

  updatePhlebotomy(sample: Phlebotomy) {
    this.phlebotomy = sample;
  }
}
