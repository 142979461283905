export class SampleWorkflow {
  _id: string;
  existsRecordId: string;
  customerType: string;
  lab: string;
  workflowSteps: string;
  required: boolean;
  autoReceived: boolean;
  department: string;
  registrationLocation: string;
  outsource: boolean;
  outsourceLab: string;
  stage: string;
  goToStep: string;
  action: string;
  tatInMin: number;
  enteredBy: string;
  dateCreation: Date;
  environment: string;
  companyCode: string;
  dateOfEntry: Date;
  lastUpdated: Date;

  static types = {
    _id: 'string',
    existsRecordId: 'string',
    customerType: 'string',
    lab: 'string',
    workflowSteps: 'string',
    required: 'boolean',
    autoReceived: 'boolean',
    department: 'string',
    registrationLocation: 'string',
    outsource: 'boolean',
    outsourceLab: 'string',
    stage: 'string',
    goToStep: 'string',
    action: 'string',
    tatInMin: 'number',
    enteredBy: 'string',
    dateCreation: 'Date',
    environment: 'string',
    companyCode: 'string',
    dateOfEntry: 'Date',
    lastUpdated: 'Date',
  };

  constructor(rawData: { [key in string]: any }) {
    this._id = rawData._id;
    this.existsRecordId = rawData.existsRecordId;
    this.customerType = rawData.customerType;
    this.lab = rawData.lab;
    this.workflowSteps = rawData.workflowSteps;
    this.required = rawData.required;
    this.department = rawData.department;
    this.registrationLocation = rawData.registrationLocation;
    this.autoReceived = rawData.autoReceived;
    this.outsource = rawData.outsource;
    this.outsourceLab = rawData.outsourceLab;
    this.stage = rawData.stage;
    this.goToStep = rawData.goToStep;
    this.action = rawData.action;
    this.tatInMin = rawData.tatInMin;
    this.environment = rawData.environment;
    this.companyCode = rawData.companyCode;
    this.enteredBy = rawData.enteredBy;
    this.dateCreation = rawData.dateCreation;
    this.dateOfEntry = rawData.dateOfEntry;
    this.lastUpdated = rawData.lastUpdated;
  }
}
