import common_en_sa from './en/common.en-us.json';
import common_hi from './hi/common.hi.json';

export const englishTranslations = {
  ...common_en_sa,
};

export const hindiTranslations = {
  ...common_hi,
};
