import { makeObservable, action, observable, computed } from 'mobx';
import { TimeSlot } from '../models';
import dayjs from 'dayjs';
// import { DeginisationService } from '../services';

export class TimeSlotStore {
  listTimeSlot!: TimeSlot[];
  listTimeSlotCount = 0;
  timeSlot!: TimeSlot;

  constructor() {
    this.listTimeSlot = [];
    this.timeSlot = new TimeSlot({});
    this.reset();
    makeObservable<TimeSlotStore, any>(this, {
      listTimeSlot: observable,
      listTimeSlotCount: observable,

      TimeSlotService: computed,
      fetchListTimeSlot: action,
      updateTimeSlot: action,
      reset: action,
    });
  }

  reset() {
    this.timeSlot = new TimeSlot({});
    this.listTimeSlot = [];
    this.listTimeSlotCount = 0;
  }

  get TimeSlotService() {
    return;
  }

  fetchListTimeSlot(page?, limit?) {
    // this.DeginisationService.listDeginisation(page, limit);
  }

  updateTimeSlot = (chart: TimeSlot) => {
    this.timeSlot = chart;
  };
}
