export class FieldExecutive {
  _id: string;
  lab: string;
  feCode: string;
  feName: string;
  feFor: string;
  feType: string;
  reportingLocation: string;
  pinCode: string;
  city: string;
  hub: string;
  routeType: string;
  employeeVendorCode: string;
  vendorName: string;
  employeeMobileNumber: number;
  isCentralFolder: boolean;
  supervisorContactNumber: number;
  supervisorEmailId: string;
  managerContactNumber: number;
  managerEmailId: string;
  contactNo: number;
  photo: any;
  userId: string;
  averageKm: string;
  mobileNo: number;
  email: string;
  dateCreation: Date;
  dateActive: Date;
  dateExpire: Date;
  enteredBy: string;
  companyCode: string;
  status: string;
  environment: string;
  dateOfEntry: Date;
  lastUpdated: Date;

  static types = {
    _id: 'string',
    lab: 'string',
    feCode: 'string',
    feName: 'string',
    feFor: 'string',
    feType: 'string',
    reportingLocation: 'string',
    pinCode: 'string',
    city: 'string',
    hub: 'string',
    routeType: 'string',
    employeeVendorCode: 'string',
    vendorName: 'string',
    employeeMobileNumber: 'number',
    isCentralFolder: 'boolean',
    supervisorContactNumber: 'number',
    supervisorEmailId: 'string',
    managerContactNumber: 'number',
    managerEmailId: 'string',
    contactNo: 'number',
    photo: 'any',
    userId: 'string',
    averageKm: 'string',
    mobileNo: 'number',
    email: 'string',
    dateCreation: 'Date',
    dateActive: 'Date',
    dateExpire: 'Date',
    enteredBy: 'string',
    companyCode: 'string',
    status: 'string',
    environment: 'string',
    dateOfEntry: 'Date',
    lastUpdated: 'Date',
  };

  constructor(rawData: { [key in string]: any }) {
    this._id = rawData._id;
    this.lab = rawData.lab;
    this.feCode = rawData.feCode;
    this.feName = rawData.feName;
    this.feFor = rawData.feFor;
    this.feType = rawData.feType;
    this.reportingLocation = rawData.reportingLocation;
    this.pinCode = rawData.pinCode;
    this.city = rawData.city;
    this.hub = rawData.hub;
    this.routeType = rawData.routeType;
    this.employeeVendorCode = rawData.employeeVendorCode;
    this.vendorName = rawData.vendorName;
    this.employeeMobileNumber = rawData.employeeMobileNumber;
    this.isCentralFolder = rawData.isCentralFolder;
    this.supervisorContactNumber = rawData.supervisorContactNumber;
    this.supervisorEmailId = rawData.supervisorEmailId;
    this.managerContactNumber = rawData.managerContactNumber;
    this.managerEmailId = rawData.managerEmailId;
    this.contactNo = rawData.contactNo;
    this.photo = rawData.photo;
    this.userId = rawData.userId;
    this.averageKm = rawData.averageKm;
    this.mobileNo = rawData.mobileNo;
    this.email = rawData.email;
    this.dateCreation = rawData.dateCreation;
    this.dateActive = rawData.dateActive;
    this.dateExpire = rawData.dateExpire;
    this.enteredBy = rawData.enteredBy;
    this.companyCode = rawData.companyCode;
    this.status = rawData.status;
    this.environment = rawData.environment;
    this.dateOfEntry = rawData.dateOfEntry;
    this.lastUpdated = rawData.lastUpdated;
  }
}
