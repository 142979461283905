/* eslint-disable */
import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { useStores } from '@/stores';
import { getDefaultLookupItem } from '@/library/utils';

export const FieldWiseControlHoc = (Component: React.FC<any>) => {
  return observer((props: any): JSX.Element => {
    const { loginStore, fieldWiseControlStore, routerStore } = useStores();
    useEffect(() => {
      fieldWiseControlStore.updateFieldWiseControl({
        ...fieldWiseControlStore.fieldWiseControl,
        status: getDefaultLookupItem(routerStore.lookupItems, 'STATUS'),
      });
      if (loginStore.login && loginStore.login.role !== 'ADMINISTRATOR') {
        fieldWiseControlStore.updateFieldWiseControl({
          ...fieldWiseControlStore.fieldWiseControl,
          environment: loginStore.login.environment,
        });
      }
    }, [loginStore.login, routerStore.lookupItems]);

    return <Component {...props} />;
  });
};
