import async from '@/layouts/components/async.component';

const SampleWorkflow = async(
  () => import('../sample-workflow/screens/sample-workflow.screen'),
);

export const workflowManagerRoutes = {
  path: '/workflow-manager',
  name: 'Workflow Manager',
  title: 'WORKFLOW MANAGER',
  order: 11,
  icon: 'TbTopologyStar3',
  children: [
    {
      path: '/workflow-manager/sample-workflow',
      name: 'Sample Workflow',
      icon: 'BiCommentDetail',
      component: SampleWorkflow,
    },
  ],
};
