export class TransmittedMessage {
  _id: string;
  labId: string;
  instType: string;
  instId: string;
  protocol: string;
  segmentMessage: string;
  segmentOrder: number;
  segmentArray: Array<any>;
  companyCode: string;
  environment: string;
  status: string;
  dateOfEntry: Date;
  lastUpdated: Date;

  static types = {
    _id: 'string',
    labId: 'string',
    instType: 'string',
    instId: 'string',
    protocol: 'string',
    segmentMessage: 'string',
    segmentOrder: 'number',
    segmentArray: 'Array',
    companyCode: 'string',
    environment: 'string',
    status: 'string',
    dateOfEntry: 'Date',
    lastUpdated: 'Date',
  };

  constructor(rawData: { [key in string]: any }) {
    this._id = rawData._id;
    this.labId = rawData.labId;
    this.instType = rawData.instType;
    this.instId = rawData.instId;
    this.protocol = rawData.protocol;
    this.segmentMessage = rawData.segmentMessage;
    this.segmentOrder = rawData.segmentOrder;
    this.segmentArray = rawData.segmentArray;
    this.companyCode = rawData.companyCode;
    this.environment = rawData.environment;
    this.status = rawData.status;
    this.dateOfEntry = rawData.dateOfEntry;
    this.lastUpdated = rawData.lastUpdated;
  }
}
