export class Role {
  _id: string;
  role: any;
  rolePermission: any[];
  companyCode: string; // Added companyCode
  environment: string; // Added environment
  dateOfEntry: Date; // Added dateOfEntry
  lastUpdated: Date; // Added lastUpdated

  static types = {
    _id: 'string',
    role: 'any',
    rolePermission: 'Array',
    companyCode: 'string', // Added companyCode
    environment: 'string', // Added environment
    dateOfEntry: 'Date', // Added dateOfEntry
    lastUpdated: 'Date', // Added lastUpdated
  };

  constructor(rawData: { [key in string]: any }) {
    this._id = rawData._id;
    this.role = rawData.role;
    this.rolePermission = rawData.rolePermission;
    this.companyCode = rawData.companyCode; // Added companyCode
    this.environment = rawData.environment; // Added environment
    this.dateOfEntry = rawData.dateOfEntry; // Added dateOfEntry
    this.lastUpdated = rawData.lastUpdated; // Added lastUpdated
  }
}

export class RoleMapping {
  code: string;
  description: string;
  dateOfEntry: string;
  lastUpdated: string;

  static types = {
    code: 'string',
    description: 'string',
    dateOfEntry: 'string',
    lastUpdated: 'string',
  };

  constructor(rawData: { [key in string]: any }) {
    this.code = rawData.code;
    this.description = rawData.description;
    this.dateOfEntry = rawData.dateOfEntry;
    this.lastUpdated = rawData.lastUpdated;
  }
}
