import { makeObservable, action, observable, computed } from 'mobx';
import { CollectionLocation } from '../models';
import dayjs from 'dayjs';
// import { DeginisationService } from '../services';

export class CollectionLocationStore {
  listCollectionLocation!: CollectionLocation[];
  listCollectionLocationCount = 0;
  collectionLocation!: CollectionLocation;

  constructor() {
    this.listCollectionLocation = [];
    this.collectionLocation = new CollectionLocation({});
    this.reset();
    makeObservable<CollectionLocationStore, any>(this, {
      listCollectionLocation: observable,
      listCollectionLocationCount: observable,

      CollectionLocationService: computed,
      fetchListCollectionLocation: action,
      updateCollectionLocation: action,
      reset: action,
    });
  }

  reset() {
    this.collectionLocation = new CollectionLocation({
      status: 'A',
      dateCreation: new Date(),
      dateActive: new Date(),
      dateExpire: new Date(
        dayjs(new Date()).add(365, 'days').format('YYYY-MM-DD'),
      ),
    });
    this.listCollectionLocation = [];
    this.listCollectionLocationCount = 0;
  }

  get CollectionLocationService() {
    return;
  }

  fetchListCollectionLocation(page?, limit?) {
    // this.DeginisationService.listDeginisation(page, limit);
  }

  updateCollectionLocation = (collection: CollectionLocation) => {
    this.collectionLocation = collection;
  };
}
