import { dashboardRouter as dashboardRoutes } from '@/routes';

export const getRouterList = () => {
  const router = dashboardRoutes
    ?.map(item => {
      if (item.name == 'Dashboard') return;
      if (item.name == 'Registration') {
        const children: any = [];
        item.children?.forEach(itemCh => {
          if (itemCh.name == 'Patient Registration') {
            children.push(
              {
                ...itemCh,
                name: 'Patient Registration-Patient Manager',
              },
              {
                ...itemCh,
                name: 'Patient Registration-Patient Visit',
              },
              {
                ...itemCh,
                name: 'Patient Registration-Patient Order',
              },
              {
                ...itemCh,
                name: 'Patient Registration-Patient Test',
              },
              {
                ...itemCh,
                name: 'Patient Registration-Patient Result',
              },
              {
                ...itemCh,
                name: 'Patient Registration-Patient Sample',
              },
            );
          } else {
            children.push({
              ...itemCh,
            });
          }
        });
        return {
          ...item,
          children: children?.map(e => {
            return {
              ...e,
              title: e.name,
            };
          }),
        };
      } else if (item.name == 'Report Builder') {
        const children: any = [];
        item.children?.forEach(itemCh => {
          if (itemCh.name == 'Report Settings') {
            children.push(
              {
                ...itemCh,
                name: 'Report Settings-Page Layout',
              },
              {
                ...itemCh,
                name: 'Report Settings-Page Branding',
              },
              {
                ...itemCh,
                name: 'Report Settings-Report Body',
              },
              {
                ...itemCh,
                name: 'Report Settings-Template Patient Result',
              },
            );
          }
        });
        return {
          ...item,
          children: children?.map(e => {
            return {
              ...e,
              title: e.name,
            };
          }),
        };
      }
      return {
        ...item,
        children: item?.children?.map(e => {
          return {
            ...e,
            title: e.name,
          };
        }),
      };
    })
    ?.filter(item => item);
  return router;
};
