export const getBillFreUnit = (unit: string) => {
  switch (unit?.toUpperCase()) {
    case 'Y':
      return 'Yearly';
    case 'M':
      return 'Monthly';
    case 'D':
      return 'Daily';
    case 'W':
      return 'Weekly';
    case 'F':
      return 'Fortnightly';
    default:
      return unit;
  }
};

export const getBillingON = (unit: string) => {
  switch (unit?.toUpperCase()) {
    case 'F':
      return 'Full Amount';
    case 'D':
      return 'Discounted Amount';
    default:
      return unit;
  }
};
