import { makeObservable, action, observable, computed } from 'mobx';
import { Route } from '../models';
import dayjs from 'dayjs';
// import { DeginisationService } from '../services';

export class RouteStore {
  listRoute!: Route[];
  listRouteCount = 0;
  route!: Route;

  constructor() {
    this.listRoute = [];
    this.route = new Route({});
    this.reset();
    makeObservable<RouteStore, any>(this, {
      listRoute: observable,
      listRouteCount: observable,

      RouteService: computed,
      fetchListRoute: action,
      updateRoute: action,
      reset: action,
    });
  }

  reset() {
    this.route = new Route({
      startTime: '06:00 am',
      endTime: '07:00 pm',
      actualTime: '07:00 pm',
      status: 'A',
      dateCreation: new Date(),
      dateActive: new Date(),
      dateExpire: new Date(
        dayjs(new Date()).add(365, 'days').format('YYYY-MM-DD'),
      ),
    });
    this.listRoute = [];
    this.listRouteCount = 0;
  }

  get RouteService() {
    return;
  }

  fetchListRoute(page?, limit?) {
    // this.DeginisationService.listDeginisation(page, limit);
  }

  updateRoute = (chart: Route) => {
    this.route = chart;
  };
}
