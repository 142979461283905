export class ContactInformation {
  _id: string;
  post: string;
  locationCode: string;
  feCode: string;
  feName: string;
  code: string;
  name: string;
  mobile: string;
  email: string;
  contact: string;
  dateCreation: Date;
  dateActive: Date;
  dateExpire: Date;
  enteredBy: string;
  companyCode: string;
  status: string;
  environment: string;
  dateOfEntry: Date;
  lastUpdated: Date;

  static types = {
    _id: 'string',
    post: 'string',
    locationCode: 'string',
    feCode: 'string',
    feName: 'string',
    code: 'string',
    name: 'string',
    mobile: 'string',
    email: 'string',
    contact: 'string',
    dateCreation: 'Date',
    dateActive: 'Date',
    dateExpire: 'Date',
    enteredBy: 'string',
    companyCode: 'string',
    status: 'string',
    environment: 'string',
    dateOfEntry: 'Date',
    lastUpdated: 'Date',
  };

  constructor(rawData: { [key in string]: any }) {
    this._id = rawData._id;
    this.post = rawData.post;
    this.locationCode = rawData.locationCode;
    this.feCode = rawData.feCode;
    this.feName = rawData.feName;
    this.code = rawData.code;
    this.name = rawData.name;
    this.mobile = rawData.mobile;
    this.email = rawData.email;
    this.contact = rawData.contact;
    this.dateCreation = rawData.dateCreation;
    this.dateActive = rawData.dateActive;
    this.dateExpire = rawData.dateExpire;
    this.enteredBy = rawData.enteredBy;
    this.companyCode = rawData.companyCode;
    this.status = rawData.status;
    this.environment = rawData.environment;
    this.dateOfEntry = rawData.dateOfEntry;
    this.lastUpdated = rawData.lastUpdated;
  }
}
