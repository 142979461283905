export class TransactionHeader {
  _id: string;
  rLab: string;
  headerId: number;
  collectionCenter: string;
  corporateCode: string;
  corporateName: string;
  labId: string;
  invoiceAc: number;
  invoiceDate: Date;
  actionDate: Date;
  registrationDate: Date;
  dueDate: Date;
  reportingDate: Date;
  doctorId: number;
  pId: number;
  priceList: string;
  grossAmount: number;
  netAmount: number;
  discountAmount: number;
  discountPer: number;
  miscellaneousCharges: number;
  receivedAmount: number;
  balance: number;
  acClass: string;
  accountType: string;
  customerName: string;
  customerContactNo: string;
  customerGroup: string;
  allMiscCharges: Array<any>;
  discountCharges: object;
  modeOfPayment: string;
  billingOn: string;
  billingFrequency: string;
  companyCode: string;
  environment: string;
  status: string;
  enteredBy: string;
  patientOrderId: string;
  visitId: number;
  documentType: string;
  dateOfEntry: Date;
  lastUpdated: Date;

  static types = {
    _id: 'string',
    rLab: 'string',
    headerId: 'number',
    collectionCenter: 'string',
    corporateCode: 'string',
    corporateName: 'string',
    labId: 'string',
    invoiceAc: 'number',
    invoiceDate: 'Date',
    actionDate: 'Date',
    registrationDate: 'Date',
    dueDate: 'Date',
    reportingDate: 'Date',
    doctorId: 'number',
    pId: 'number',
    priceList: 'string',
    grossAmount: 'number',
    netAmount: 'number',
    discountAmount: 'number',
    discountPer: 'number',
    miscellaneousCharges: 'number',
    receivedAmount: 'number',
    balance: 'number',
    acClass: 'string',
    accountType: 'string',
    customerName: 'string',
    customerContactNo: 'string',
    customerGroup: 'string',
    allMiscCharges: 'Array',
    discountCharges: 'object',
    modeOfPayment: 'string',
    billingOn: 'string',
    billingFrequency: 'string',
    companyCode: 'string',
    environment: 'string',
    status: 'string',
    enteredBy: 'string',
    patientOrderId: 'string',
    visitId: 'number',
    documentType: 'string',
    dateOfEntry: 'Date',
    lastUpdated: 'Date',
  };

  constructor(rawData: { [key in string]: any }) {
    this._id = rawData._id;
    this.rLab = rawData.rLab;
    this.headerId = rawData.headerId;
    this.collectionCenter = rawData.collectionCenter;
    this.corporateCode = rawData.corporateCode;
    this.corporateName = rawData.corporateName;
    this.labId = rawData.labId;
    this.invoiceAc = rawData.invoiceAc;
    this.invoiceDate = rawData.invoiceDate;
    this.actionDate = rawData.actionDate;
    this.registrationDate = rawData.registrationDate;
    this.dueDate = rawData.dueDate;
    this.reportingDate = rawData.reportingDate;
    this.doctorId = rawData.doctorId;
    this.pId = rawData.pId;
    this.priceList = rawData.priceList;
    this.grossAmount = rawData.grossAmount;
    this.netAmount = rawData.netAmount;
    this.discountAmount = rawData.discountAmount;
    this.discountPer = rawData.discountPer;
    this.miscellaneousCharges = rawData.miscellaneousCharges;
    this.receivedAmount = rawData.receivedAmount;
    this.balance = rawData.balance;
    this.acClass = rawData.acClass;
    this.accountType = rawData.accountType;
    this.customerName = rawData.customerName;
    this.customerContactNo = rawData.customerContactNo;
    this.customerGroup = rawData.customerGroup;
    this.allMiscCharges = rawData.allMiscCharges;
    this.discountCharges = rawData.discountCharges;
    this.modeOfPayment = rawData.modeOfPayment;
    this.billingOn = rawData.billingOn;
    this.billingFrequency = rawData.billingFrequency;
    this.companyCode = rawData.companyCode;
    this.environment = rawData.environment;
    this.status = rawData.status;
    this.enteredBy = rawData.enteredBy;
    this.patientOrderId = rawData.patientOrderId;
    this.visitId = rawData.visitId;
    this.documentType = rawData.documentType;
    this.dateOfEntry = rawData.dateOfEntry;
    this.lastUpdated = rawData.lastUpdated;
  }
}
