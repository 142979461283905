import async from '@/layouts/components/async.component';

const CollectionLocation = async(
  () => import('../collection-location/screens/collection-location.screen'),
);

const FieldExecutive = async(
  () => import('../field-executive/screens/field-executive.screen'),
);

const BookingChart = async(
  () => import('../booking-chart/screens/booking-chart.screen'),
);

const FeAllocation = async(
  () => import('../fe-allocation/screens/fe-allocation.screen'),
);

const Route = async(() => import('../route/screens/route.screen'));

const ContactInformation = async(
  () => import('../contact-information/screens/contact-information.screen'),
);

const TimeSlot = async(() => import('../time-slot/screens/time-slot.screen'));

export const homeCollectionRoutes = {
  path: '/home-collection',
  name: 'Home Collection',
  title: 'HOME COLLECTION',
  order: 7,
  icon: 'BsFillCollectionFill',
  badgeColor: '#ffffff',
  children: [
    {
      path: '/home-collection/booking-chart',
      name: 'Booking Chart',
      icon: 'BiCommentDetail',
      component: BookingChart,
    },
    {
      path: '/home-collection/collection-location',
      name: 'Collection Location',
      icon: 'BiCommentDetail',
      component: CollectionLocation,
    },
    {
      path: '/home-collection/field-executive',
      name: 'Field Executive',
      icon: 'BiCommentDetail',
      component: FieldExecutive,
    },

    {
      path: '/home-collection/field-executive',
      name: 'Field Executive',
      icon: 'BiCommentDetail',
      component: BookingChart,
    },
    {
      path: '/home-collection/route',
      name: 'Route',
      icon: 'BiCommentDetail',
      component: Route,
    },
    {
      path: '/home-collection/fe-allocation',
      name: 'FE Allocation',
      icon: 'BiCommentDetail',
      component: FeAllocation,
    },
    {
      path: '/home-collection/contact-information',
      name: 'Contact Information',
      icon: 'BiCommentDetail',
      component: ContactInformation,
    },
    {
      path: '/home-collection/time-slot',
      name: 'Time Slot',
      icon: 'BiCommentDetail',
      component: TimeSlot,
    },
  ],
};
