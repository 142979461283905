/**
 * @fileoverview Use this file invoke LimsPlus API
 * implementation related to LimsPlus standards
 
 * @author limsplus
 */

import { client, ServiceResponse } from '@/core-services/graphql/apollo-client';
import { stores } from '@/stores';
import {
  DELIVERY_QUEUE_LIST,
  UPDATE_DELIVERY_QUEUE,
  UPDATE_DELIVERY_QUEUE_BY_VISIT_IDS,
  FILTER,
  MEDICAL_REPORT,
  FIND_BY_FIELDS,
  PATIENT_REPORT_LIST,
  REPORT_UPLOAD,
} from './mutation-delivery-queue';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

export class DeliveryQueueService {
  listPatientReports = variables =>
    new Promise<any>((resolve, reject) => {
      client
        .mutate({
          mutation: PATIENT_REPORT_LIST,
          variables: {
            input: { ...variables },
          },
        })
        .then((response: any) => {
          resolve(response.data);
        })
        .catch(error =>
          reject(new ServiceResponse<any>(0, error.message, undefined)),
        );
    });

  listDeliveryQueue = (filter = {}, page = 0) =>
    new Promise<any>((resolve, reject) => {
      client
        .mutate({
          mutation: DELIVERY_QUEUE_LIST,
          variables: {
            input: {
              isReCall: false,
              filter,
              page,
            },
          },
        })
        .then((response: any) => {
          stores.deliveryQueueStore.updateReportDeliveryList(response.data);
          resolve(response.data);
        })
        .catch(error =>
          reject(new ServiceResponse<any>(0, error.message, undefined)),
        );
    });

  updateDeliveryQueue = (variables: any) =>
    new Promise<any>((resolve, reject) => {
      client
        .mutate({
          mutation: UPDATE_DELIVERY_QUEUE,
          variables,
        })
        .then((response: any) => {
          resolve(response.data);
        })
        .catch(error =>
          reject(new ServiceResponse<any>(0, error.message, undefined)),
        );
    });

  updateDeliveryQueueByVisitIds = (variables: any) =>
    new Promise<any>((resolve, reject) => {
      client
        .mutate({
          mutation: UPDATE_DELIVERY_QUEUE_BY_VISIT_IDS,
          variables,
        })
        .then((response: any) => {
          resolve(response.data);
        })
        .catch(error =>
          reject(new ServiceResponse<any>(0, error.message, undefined)),
        );
    });

  filter = (variables: any) =>
    new Promise<any>((resolve, reject) => {
      console.log({ variables });
      stores.uploadLoadingFlag(false);
      client
        .mutate({
          mutation: FILTER,
          variables,
        })
        .then((response: any) => {
          if (!response.data.filterDeliveryQueue.success)
            return this.listDeliveryQueue();
          stores.deliveryQueueStore.filterReportDeliveryList(response.data);
          stores.uploadLoadingFlag(false);
          resolve(response.data);
        })
        .catch(error =>
          reject(new ServiceResponse<any>(0, error.message, undefined)),
        );
    });

  getMedicalReportDetails = (variables: any) =>
    new Promise<any>((resolve, reject) => {
      client
        .mutate({
          mutation: MEDICAL_REPORT,
          variables,
        })
        .then((response: any) => {
          resolve(response.data);
        })
        .catch(error =>
          reject(new ServiceResponse<any>(0, error.message, undefined)),
        );
    });

  findByFields = (variables: any) =>
    new Promise<any>((resolve, reject) => {
      stores.uploadLoadingFlag(false);
      client
        .mutate({
          mutation: FIND_BY_FIELDS,
          variables,
        })
        .then((response: any) => {
          stores.uploadLoadingFlag(true);
          resolve(response.data);
        })
        .catch(error =>
          reject(new ServiceResponse<any>(0, error.message, undefined)),
        );
    });

  reportUpload = (variables: any) =>
    new Promise<any>((resolve, reject) => {
      stores.uploadLoadingFlag(false);
      client
        .mutate({
          mutation: REPORT_UPLOAD,
          variables,
        })
        .then((response: any) => {
          stores.uploadLoadingFlag(true);
          resolve(response.data);
        })
        .catch(error =>
          reject(new ServiceResponse<any>(0, error.message, undefined)),
        );
    });
}
