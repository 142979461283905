import React, { useEffect } from 'react';
import { AutoCompleteFilterMultipleSelectMultipleFieldsDisplay } from 'react-restyle-components';
import { useStores } from '@/stores';
import { observer } from 'mobx-react';

interface AutocompleteRoleSelectProps {
  role: any;
  onUpdate: (items: any) => void;
}

export const AutocompleteRoleSelect = observer(
  ({ role, onUpdate }: AutocompleteRoleSelectProps) => {
    const {
      loginStore,
      roleStore,
      fieldWiseControlStore,
      routerStore,
      companyStore,
    } = useStores();

    useEffect(() => {
      fieldWiseControlStore.updateSelectedItems({
        ...fieldWiseControlStore.selectedItems,
        role,
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [role]);
    return (
      <AutoCompleteFilterMultipleSelectMultipleFieldsDisplay
        loader={false}
        placeholder='Search by code'
        isSelectedStringArray={true}
        uniqueField='code'
        data={{
          list: roleStore.listRole?.filter(item => item.status == 'A') || [],
          selected: fieldWiseControlStore.selectedItems.role,
          displayKey: ['code', 'description'],
        }}
        onUpdate={item => {
          const role = fieldWiseControlStore.selectedItems.role;
          onUpdate(role);
        }}
        onFilter={(value: string) => {
          roleStore.RoleService.filterByFields({
            input: {
              filter: {
                fields: ['code', 'description'],
                srText: value,
              },
              page: 0,
              limit: 10,
            },
          });
        }}
        onSelect={item => {
          let role = fieldWiseControlStore.selectedItems?.role;
          if (!item.selected) {
            if (role && role.length > 0) {
              role.push(item.code);
            } else role = [item.code];
          } else {
            role = role?.filter(items => {
              return items !== item?.code;
            });
          }
          fieldWiseControlStore.updateSelectedItems({
            ...fieldWiseControlStore.selectedItems,
            role,
          });
        }}
      />
    );
  },
);
