export class BookingChart {
  _id: string;
  lab: string;
  bookingCollection: string;
  startingCollection: string;
  pickupLocation: string;
  fieldExecutive: string;
  route: string;
  date: Date;
  timeSlot: string;
  startTime: string;
  pickupTime: string;
  dropTime: string;
  dateCreation: Date;
  dateActive: Date;
  dateExpire: Date;
  enteredBy: string;
  companyCode: string;
  status: string;
  environment: string;
  dateOfEntry: Date;
  lastUpdated: Date;

  static types = {
    _id: 'string',
    lab: 'string',
    bookingCollection: 'string',
    startingCollection: 'string',
    pickupLocation: 'string',
    fieldExecutive: 'string',
    route: 'string',
    date: 'Date',
    timeSlot: 'string',
    startTime: 'string',
    pickupTime: 'string',
    dropTime: 'string',
    dateCreation: 'Date',
    dateActive: 'Date',
    dateExpire: 'Date',
    enteredBy: 'string',
    companyCode: 'string',
    status: 'string',
    environment: 'string',
    dateOfEntry: 'Date',
    lastUpdated: 'Date',
  };

  constructor(rawData: { [key in string]: any }) {
    this._id = rawData._id;
    this.lab = rawData.lab;
    this.bookingCollection = rawData.bookingCollection;
    this.startingCollection = rawData.startingCollection;
    this.pickupLocation = rawData.pickupLocation;
    this.fieldExecutive = rawData.fieldExecutive;
    this.date = rawData.date;
    this.timeSlot = rawData.timeSlot;
    this.startTime = rawData.startTime;
    this.pickupTime = rawData.pickupTime;
    this.dropTime = rawData.dropTime;
    this.route = rawData.route;
    this.dateCreation = rawData.dateCreation;
    this.dateActive = rawData.dateActive;
    this.dateExpire = rawData.dateExpire;
    this.enteredBy = rawData.enteredBy;
    this.companyCode = rawData.companyCode;
    this.status = rawData.status;
    this.environment = rawData.environment;
    this.dateOfEntry = rawData.dateOfEntry;
    this.lastUpdated = rawData.lastUpdated;
  }
}
