import React, { useState, useMemo, useEffect } from 'react';
import { observer } from 'mobx-react';
import _ from 'lodash';
import {
  Toast,
  Header,
  PageHeading,
  PageHeadingLabDetails,
  Buttons,
  ModalConfirm,
  List,
  Svg,
  ModalImportFile,
  Icons,
  MainPageHeading,
  ManualImportTabs,
  StaticInputTable,
  ImportFile,
} from '@/library/components';
import * as XLSX from 'xlsx';
import { Styles } from '@/config';
import {
  CommonInputTable,
  SegmentMappingInputTable,
  SegmentMappingList,
} from '../components';
import { useForm } from 'react-hook-form';
import { SegmentMappingHoc } from '../hoc';
import { useStores } from '@/stores';
import { RouterFlow } from '@/flows';
import { toJS } from 'mobx';
import { resetSegmentMapping } from '../startup';

const SegmentMapping = SegmentMappingHoc(
  observer(() => {
    const {
      loginStore,
      interfaceManagerStore,
      segmentMappingStore,
      routerStore,
    } = useStores();
    const {
      control,
      handleSubmit,
      formState: { errors },
      setValue,
      reset,
    } = useForm();
    const [isImport, setIsImport] = useState<boolean>(false);
    const [arrImportRecords, setArrImportRecords] = useState<Array<any>>([]);
    const [modalImportFile, setModalImportFile] = useState({});
    const [hideAddSegmentMapping, setHideAddSegmentMapping] =
      useState<boolean>(true);
    const [saveTitle, setSaveTitle] = useState('Save');
    const [arrInstType, setArrInstType] = useState([]);
    const [modalConfirm, setModalConfirm] = useState<any>();

    useEffect(() => {
      interfaceManagerStore.interfaceManagerService
        .findByFields({
          input: {
            filter: {
              interfaceType: 'INSTRUMENT',
            },
          },
        })
        .then(res => {
          if (res.findByFieldsInterfaceManager.success) {
            setArrInstType(res.findByFieldsInterfaceManager.data);
          }
        });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleFileUpload = (file: any) => {
      const reader = new FileReader();
      reader.addEventListener('load', (evt: any) => {
        /* Parse data */
        const bstr = evt.target.result;
        const wb = XLSX.read(bstr, { type: 'binary' });
        /* Get first worksheet */
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        /* Convert to array of objects */
        const data = XLSX.utils.sheet_to_json(ws, { raw: true });
        console.log({ data });

        let object: any[] = [];
        let fileImport: boolean = false;

        // Process each row (object) in the file
        data.forEach((item: any, index: number) => {
          object.push({
            index,
            instType: item['Inst Type'],
            dataFlow: item['Data Flow'],
            protocol: item.Protocol,
            segments: item.Segments,
            segmentOrder: item['Segment Order'],
            segmentRequired: item['Segment Required'] === 'Yes',
            elementNo: item['Element No'],
            elementName: item['Element Name'],
            limsTables: item['Lims Tables'],
            limsDocumentType: item['Lims Document Type'],
            limsFields: item['Lims Fields'],
            elementRequired: item['Element Required'] === 'Yes',
            elementSequence: item['Element Sequence'],
            transmittedData: item['Transmitted Data'],
            defaultValue: item['Default Value'],
            fieldArray: item['Field Array'],
            repeatDelimiter: item['Repeat Delimiter'] === 'Yes',
            fieldType: item['Field Type'],
            fieldLength: item['Field Length'],
            requiredForLims: item['Required For Lims'] === 'Yes',
            environment: item.Environment,
            companyCode: item['Company Code'],
          });
          fileImport = true;
        });

        // Proceed if the file was imported successfully
        if (fileImport) {
          object = JSON.parse(JSON.stringify(object));
          segmentMappingStore.segmentMappingService
            .addSegmentMapping({
              input: {
                filter: { segmentMapping: object },
              },
            })
            .then((res: any) => {
              if (res.createSegmentMapping.success) {
                Toast.success({
                  message: `😊 ${res.createSegmentMapping.message}`,
                });
                setTimeout(() => {
                  window.location.reload();
                }, 2000);
              }
            });
        }
      });
      reader.readAsBinaryString(file);
    };

    const onSubmitSegmentMapping = () => {
      if (segmentMappingStore.segmentMapping?.length > 0) {
        segmentMappingStore.segmentMappingService
          .addSegmentMapping({
            input: {
              filter: { segmentMapping: segmentMappingStore.segmentMapping },
            },
          })
          .then(res => {
            if (res.createSegmentMapping.success) {
              Toast.success({
                message: `😊 ${res.createSegmentMapping.message}`,
              });
              if (saveTitle === 'Save') {
                setHideAddSegmentMapping(true);
                reset();
                resetSegmentMapping();
                segmentMappingStore.updateSegmentMapping([]);
              }
            }
          });
      } else {
        Toast.warning({
          message: '😔 Please enter all information!',
        });
      }
    };

    const inputTable = useMemo(
      () =>
        segmentMappingStore.segmentMapping?.length > 0 && (
          <div className='p-2 rounded-lg shadow-xl overflow-auto'>
            <SegmentMappingInputTable
              data={toJS(segmentMappingStore.segmentMapping)}
              extraData={routerStore}
              onDelete={index => {
                const position = _.findIndex(
                  segmentMappingStore.segmentMapping,
                  {
                    index,
                  },
                );
                const firstArr =
                  segmentMappingStore.segmentMapping?.slice(0, position) || [];
                const secondArr =
                  segmentMappingStore.segmentMapping?.slice(position + 1) || [];
                const finalArray = [...firstArr, ...secondArr];
                segmentMappingStore.updateSegmentMapping(finalArray);
              }}
              onUpdateItems={(items, index) => {
                const position = _.findIndex(
                  segmentMappingStore.segmentMapping,
                  {
                    index,
                  },
                );
                const segmentMapping = segmentMappingStore.segmentMapping;
                segmentMapping[position] = {
                  ...segmentMapping[position],
                  ...items,
                };
                segmentMappingStore.updateSegmentMapping(segmentMapping);
              }}
              onDuplicate={item => {
                const segmentMapping = segmentMappingStore.segmentMapping;
                segmentMapping?.push({
                  ...item,
                  index: segmentMapping?.length + 1,
                });
                segmentMappingStore.updateSegmentMapping(segmentMapping);
              }}
            />
          </div>
        ),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [JSON.parse(JSON.stringify(segmentMappingStore.segmentMapping))],
    );

    const segmentListTable = useMemo(
      () => (
        <SegmentMappingList
          data={segmentMappingStore.listSegmentMapping || []}
          totalSize={segmentMappingStore.listSegmentMappingCount}
          extraData={{
            lookupItems: routerStore.lookupItems,
            arrInstType,
          }}
          isView={RouterFlow.checkPermission(
            routerStore.userPermission,
            'View',
          )}
          isDelete={RouterFlow.checkPermission(
            routerStore.userPermission,
            'Delete',
          )}
          isUpdate={RouterFlow.checkPermission(
            routerStore.userPermission,
            'Update',
          )}
          isExport={RouterFlow.checkPermission(
            routerStore.userPermission,
            'Export',
          )}
          onDelete={selectedItem => setModalConfirm(selectedItem)}
          onSelectedRow={rows => {
            setModalConfirm({
              show: true,
              type: 'delete',
              id: rows,
              title: 'Are you sure?',
              body: 'Do you want to delete selected record?',
            });
          }}
          onUpdateFields={(fields: any, id: string) => {
            setModalConfirm({
              show: true,
              type: 'updateFields',
              data: { fields, id },
              title: 'Are you sure?',
              body: 'Do you want to update this record?',
            });
          }}
          onPageSizeChange={(page, limit) => {
            segmentMappingStore.fetchListSegmentMapping(page, limit);
            global.filter = { mode: 'pagination', page, limit };
          }}
          onFilter={(type, filter, page, limit) => {
            segmentMappingStore.segmentMappingService.filter({
              input: { type, filter, page, limit },
            });
            global.filter = { mode: 'filter', type, filter, page, limit };
          }}
        />
      ),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [segmentMappingStore.listSegmentMapping],
    );

    return (
      <>
        <MainPageHeading
          title={routerStore.selectedComponents?.title || ''}
          store={loginStore}
        />
        <div
          className='flex justify-end'
          style={{
            position: 'fixed',
            right: '30px',
            top: '135px',
            zIndex: 9999,
          }}
        >
          {RouterFlow.checkPermission(routerStore.userPermission, 'Add') && (
            <Buttons.ButtonCircleAddRemoveBottom
              show={hideAddSegmentMapping}
              onClick={() => setHideAddSegmentMapping(!hideAddSegmentMapping)}
            />
          )}
        </div>

        <div className=' mx-auto flex-wrap'>
          <div
            className={
              'p-2 rounded-lg shadow-xl ' +
              (hideAddSegmentMapping ? 'hidden' : 'shown')
            }
          >
            <ManualImportTabs
              isImport={isImport}
              isImportDisable={
                !RouterFlow.checkPermission(
                  toJS(routerStore.userPermission),
                  'Import',
                )
              }
              onClick={flag => {
                setIsImport(flag);
              }}
            />
            {!isImport ? (
              <>
                <CommonInputTable />
                {inputTable}
              </>
            ) : (
              <>
                {arrImportRecords?.length > 0 ? (
                  <StaticInputTable data={arrImportRecords} />
                ) : (
                  <ImportFile
                    onClick={file => {
                      handleFileUpload(file[0]);
                    }}
                  />
                )}
              </>
            )}

            <br />
            <List direction='row' space={3} align='center'>
              <Buttons.Button
                size='medium'
                type='solid'
                icon={Svg.Save}
                onClick={handleSubmit(onSubmitSegmentMapping)}
              >
                {saveTitle}
              </Buttons.Button>
              <Buttons.Button
                size='medium'
                type='outline'
                icon={Svg.Remove}
                onClick={() => {
                  window.location.reload();
                }}
              >
                Clear
              </Buttons.Button>
            </List>
          </div>
        </div>

        <div className='p-2 rounded-lg shadow-xl overflow-scroll'>
          {segmentListTable}
        </div>
        <ModalImportFile
          accept='.csv,.xlsx,.xls'
          {...modalImportFile}
          click={(file: any) => {
            setModalImportFile({ show: false });
            handleFileUpload(file);
          }}
          close={() => {
            setModalImportFile({ show: false });
          }}
        />
        <ModalConfirm
          {...modalConfirm}
          click={action => {
            setModalConfirm({ show: false });
            if (segmentMappingStore.selectedItems) {
              if (action === 'delete') {
                segmentMappingStore.segmentMappingService
                  .deleteSegmentMapping({
                    input: {
                      id: modalConfirm.id,
                    },
                  })
                  .then(res => {
                    if (res.removeSegmentMapping.success) {
                      segmentMappingStore.updateSelectedItem([]);
                      Toast.success({
                        message: `😊 ${res.removeSegmentMapping.message}`,
                      });
                      if (global?.filter?.mode == 'pagination')
                        segmentMappingStore.fetchListSegmentMapping(
                          global?.filter?.page,
                          global?.filter?.limit,
                        );
                      else if (global?.filter?.mode == 'filter')
                        segmentMappingStore.segmentMappingService.filter({
                          input: {
                            type: global?.filter?.type,
                            filter: global?.filter?.filter,
                            page: global?.filter?.page,
                            limit: global?.filter?.limit,
                          },
                        });
                      else segmentMappingStore.fetchListSegmentMapping();
                    }
                  });
              } else if (action == 'updateFields') {
                segmentMappingStore.segmentMappingService
                  .updateSingleFiled({
                    input: {
                      ...modalConfirm.data.fields,
                      _id: modalConfirm.data.id,
                    },
                  })
                  .then(res => {
                    if (res.updateSegmentMapping.success) {
                      Toast.success({
                        message: ` ${res.updateSegmentMapping.message}`,
                      });
                      if (global?.filter?.mode == 'pagination')
                        segmentMappingStore.fetchListSegmentMapping(
                          global?.filter?.page,
                          global?.filter?.limit,
                        );
                      else if (global?.filter?.mode == 'filter')
                        segmentMappingStore.segmentMappingService.filter({
                          input: {
                            type: global?.filter?.type,
                            filter: global?.filter?.filter,
                            page: global?.filter?.page,
                            limit: global?.filter?.limit,
                          },
                        });
                      else segmentMappingStore.fetchListSegmentMapping();
                    }
                  });
              }
            }
          }}
          close={() => setModalConfirm({ show: false })}
        />
      </>
    );
  }),
);

export default SegmentMapping;
