import React, { useEffect, useState } from 'react';
import { Container } from 'reactstrap';
import { Form } from '@/library/components';

interface RowData {
  _id: string;
  result: string | { result?: string };
}

interface ModalDescriptionModifyProps {
  show?: boolean;
  row?: RowData;
  isViewMode?: boolean;
  rowValue: string;
  onClose: () => void;
  onSelect?: (item: string) => void;
}

export const ModalDescriptionModify = (props: ModalDescriptionModifyProps) => {
  const [inputValue, setInputValue] = useState<string>(props.rowValue || '');

  useEffect(() => {
    setInputValue(props.rowValue || '');
  }, [props.rowValue]);

  return (
    <Container>
      {props.show && (
        <>
          <div className='justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none'>
            <div className='relative w-auto my-6 mx-auto max-w-3xl'>
              <div className='border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none'>
                <div className='flex flex-col justify-between p-2 border-b border-solid border-gray-300 rounded-t'>
                  <h4 className='font-semibold text-lg'>
                    {props.isViewMode ? 'Update Result' : 'Enter Result'}
                  </h4>
                </div>
                <div className='relative p-2 flex-auto'>
                  <Form.MultilineInput1
                    name={`field-${props?.row?._id}`}
                    rows={3}
                    label=''
                    placeholder='Result'
                    defaultValue={inputValue}
                    onBlur={e => {
                      setInputValue(e.target.value);
                    }}
                  />
                </div>
                <div className='flex items-center justify-end p-2 border-t border-solid border-gray-300 rounded-b'>
                  <button
                    className='text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1'
                    type='button'
                    style={{ transition: 'all .15s ease' }}
                    onClick={() => {
                      props.onClose();
                    }}
                  >
                    Close
                  </button>

                  <button
                    className='bg-green-500 text-white active:bg-green-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1'
                    type='button'
                    style={{ transition: 'all .15s ease' }}
                    onClick={() => {
                      if (inputValue.trim()) {
                        props?.onSelect && props?.onSelect(inputValue);
                      }
                      props.onClose();
                    }}
                  >
                    {props.isViewMode ? 'Update' : 'Save'}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className='opacity-25 fixed inset-0 z-40 bg-black'></div>
        </>
      )}
    </Container>
  );
};
