import { makeObservable, action, observable, computed } from 'mobx';
import { TransactionHeader, TransactionLine } from '../models';
import { TransactionDetailsService } from '../services';
import _ from 'lodash';

export class TransactionDetailsStore {
  transactionHeaderList!: Array<TransactionHeader>;
  transactionHeaderListCount: number = 0;
  transactionLineList!: Array<TransactionLine>;
  transactionLineListCount: number = 0;

  constructor() {
    this.transactionHeaderList = [];
    this.transactionLineList = [];
    makeObservable<TransactionDetailsStore, any>(this, {
      transactionHeaderList: observable,
      transactionHeaderListCount: observable,
      transactionLineList: observable,
      transactionLineListCount: observable,

      transactionDetailsService: computed,

      updateTransactionHeaderList: action,
      updateTransactionLineList: action,
    });
  }

  get transactionDetailsService() {
    return new TransactionDetailsService();
  }

  updateTransactionHeaderList(res) {
    if (!Array.isArray(res)) {
      this.transactionHeaderList = res.transactionHeaders.data;
      this.transactionHeaderListCount =
        res.transactionHeaders.paginatorInfo.count;
    } else {
      this.transactionHeaderList = res;
      this.transactionHeaderListCount = res?.length || 0;
    }
  }

  updateTransactionLineList(res) {
    if (!Array.isArray(res)) {
      this.transactionLineList = _.orderBy(
        res.findByFieldsTransactionLine.data,
        ['lineId'],
        ['asc'],
      );
      this.transactionLineListCount =
        res.findByFieldsTransactionLine.data?.length;
    } else {
      this.transactionLineList = res;
      this.transactionLineListCount = res?.length || 0;
    }
  }
}
