import * as Models from '@/features/settings/users/models';
export class EnvironmentSettings {
  _id: string;
  variable: string;
  lab: any[] | string;
  user: Models.Users[] | string;
  department: any[] | string;
  value: string;
  descriptions: string;
  allLabs: boolean;
  allUsers: boolean;
  allDepartment: boolean;
  environment: string;
  documentType: string;
  status: string;
  dateOfEntry: Date;
  lastUpdated: Date;

  static types = {
    _id: 'string',
    variable: 'string',
    lab: 'Array',
    user: 'Array',
    department: 'Array',
    value: 'string',
    descriptions: 'string',
    allLabs: 'boolean',
    allUsers: 'boolean',
    allDepartment: 'boolean',
    environment: 'string',
    documentType: 'string',
    status: 'string',
    dateOfEntry: 'Date',
    lastUpdated: 'Date',
  };

  constructor(rawData: { [key in string]: any }) {
    this._id = rawData._id;
    this.variable = rawData.variable;
    this.lab = rawData.lab;
    this.user = rawData.user;
    this.department = rawData.department;
    this.value = rawData.value;
    this.descriptions = rawData.descriptions;
    this.allLabs = rawData.allLabs;
    this.allDepartment = rawData.allDepartment;
    this.allUsers = rawData.allUsers;
    this.documentType = rawData.documentType;
    this.environment = rawData.environment;
    this.status = rawData.status;
    this.dateOfEntry = rawData.dateOfEntry;
    this.lastUpdated = rawData.lastUpdated;
  }
}

export class SelectedItems {
  users: any[];
  labs: any[];
  department: any[];
  constructor(rawData: { [key in string]: any }) {
    this.users = rawData.users;
    this.labs = rawData.labs;
    this.department = rawData.department;
  }
}
