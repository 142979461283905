export class Role {
  _id?: string; // Added _id
  code?: string;
  description?: string;
  companyCode?: string; // Added companyCode
  status?: string;
  environment?: string;
  dateOfEntry?: Date; // Added dateOfEntry
  lastUpdated?: Date; // Added lastUpdated

  static types = {
    _id: 'string', // Added _id
    code: 'string',
    description: 'string',
    companyCode: 'string', // Added companyCode
    status: 'string',
    environment: 'string',
    dateOfEntry: 'Date', // Added dateOfEntry
    lastUpdated: 'Date', // Added lastUpdated
  };

  constructor(rawData: { [key in string]: any }) {
    this._id = rawData._id; // Added _id
    this.code = rawData.code;
    this.description = rawData.description;
    this.companyCode = rawData.companyCode; // Added companyCode
    this.status = rawData.status;
    this.environment = rawData.environment;
    this.dateOfEntry = rawData.dateOfEntry; // Added dateOfEntry
    this.lastUpdated = rawData.lastUpdated; // Added lastUpdated
  }
}
