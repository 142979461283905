import React from 'react';

import { FieldWiseControlStore } from './field-wise-control.store';

export const Stores = {
  fieldWiseControlStore: new FieldWiseControlStore(),
};

export const Contexts = {
  fieldWiseControlContext: React.createContext(Stores.fieldWiseControlStore),
};
