export class PatientTest {
  _id: string;
  pId: number;
  patientOrderId: string;
  labId: number;
  panelList: any[];
  orderId: number;
  panelCodes: any;
  panelCode: any[];
  panelName: string;
  testId: number;
  patientName: string;
  panelDescription: string;
  panelMethodCode: string;
  panelInterpretation: string;
  testCode: string;
  testName: string;
  testDescription: string;
  testInterpretation: string;
  confidential: boolean;
  urgent: boolean;
  critical: boolean;
  abnFlag: boolean;
  resultDate: Date;
  sampleCode: string;
  sampleType: string;
  collContainerCode: string;
  collContainerName: string;
  methodCollection: string;
  uniqueContainer: boolean;
  primaryContainer: boolean;
  aliquot: boolean;
  labSpecific: boolean;
  departmentSpecific: boolean;
  sharedSample: boolean;
  rLab: string;
  pLab: string;
  labelInstructions: string;
  status: string;
  extraData: any;
  reportOrder: number;
  specimenId: number;
  finishResult: string;
  companyCode: string;
  environment: string;
  enteredBy: string;
  documentType: string;
  dateOfEntry: Date;
  lastUpdated: Date;

  static types = {
    _id: 'string',
    pId: 'number',
    patientName: 'string',
    patientOrderId: 'string',
    labId: 'number',
    panelList: 'Array',
    orderId: 'number',
    panelCodes: 'any',
    panelCode: 'string',
    panelName: 'string',
    panelDescription: 'string',
    panelMethodCode: 'string',
    panelInterpretation: 'string',
    testCode: 'string',
    testName: 'string',
    testDescription: 'string',
    testInterpretation: 'string',
    confidential: 'boolean',
    urgent: 'boolean',
    critical: 'boolean',
    abnFlag: 'boolean',
    resultDate: 'Date',
    sampleCode: 'string',
    sampleType: 'string',
    collContainerCode: 'string',
    collContainerName: 'string',
    methodCollection: 'string',
    uniqueContainer: 'boolean',
    primaryContainer: 'boolean',
    aliquot: 'boolean',
    labSpecific: 'boolean',
    departmentSpecific: 'boolean',
    sharedSample: 'boolean',
    rLab: 'string',
    pLab: 'string',
    labelInstructions: 'string',
    status: 'string',
    testId: 'number',
    extraData: 'any',
    reportOrder: 'number',
    specimenId: 'number',
    finishResult: 'string',
    companyCode: 'string',
    environment: 'string',
    enteredBy: 'string',
    documentType: 'string',
    dateOfEntry: 'Date',
    lastUpdated: 'Date',
  };

  constructor(rawData: { [key in string]: any }) {
    this._id = rawData._id;
    this.pId = rawData.pId;
    this.patientOrderId = rawData.patientOrderId;
    this.labId = rawData.labId;
    this.orderId = rawData.orderId;
    this.panelCodes = rawData.panelCodes;
    this.panelList = rawData.panelList;
    this.panelCode = rawData.panelCode;
    this.testId = rawData.testId;
    this.patientName = rawData.patientName;
    this.panelName = rawData.panelName;
    this.panelDescription = rawData.panelDescription;
    this.panelMethodCode = rawData.panelMethodCode;
    this.panelInterpretation = rawData.panelInterpretation;
    this.testCode = rawData.testCode;
    this.testName = rawData.testName;
    this.testDescription = rawData.testDescription;
    this.testInterpretation = rawData.testInterpretation;
    this.confidential = rawData.confidential;
    this.urgent = rawData.urgent;
    this.critical = rawData.critical;
    this.abnFlag = rawData.abnFlag;
    this.resultDate = rawData.resultDate;
    this.sampleCode = rawData.sampleCode;
    this.sampleType = rawData.sampleType;
    this.collContainerCode = rawData.collContainerCode;
    this.collContainerName = rawData.collContainerName;
    this.methodCollection = rawData.methodCollection;
    this.uniqueContainer = rawData.uniqueContainer;
    this.primaryContainer = rawData.primaryContainer;
    this.aliquot = rawData.aliquot;
    this.labSpecific = rawData.labSpecific;
    this.departmentSpecific = rawData.departmentSpecific;
    this.sharedSample = rawData.sharedSample;
    this.rLab = rawData.rLab;
    this.pLab = rawData.pLab;
    this.labelInstructions = rawData.labelInstructions;
    this.status = rawData.status;
    this.extraData = rawData.extraData;
    this.reportOrder = rawData.reportOrder;
    this.specimenId = rawData.specimenId;
    this.finishResult = rawData.finishResult;
    this.companyCode = rawData.companyCode;
    this.environment = rawData.environment;
    this.enteredBy = rawData.enteredBy;
    this.documentType = rawData.documentType;
    this.dateOfEntry = rawData.dateOfEntry;
    this.lastUpdated = rawData.lastUpdated;
  }
}
