import { makeObservable, action, observable, computed } from 'mobx';
import { SampleWorkflow } from '../models';

export class SampleWorkflowStore {
  sampleWorkflow!: SampleWorkflow;
  listSampleWorkflow: SampleWorkflow[];
  listSampleWorkflowCount: number;

  constructor() {
    this.listSampleWorkflow = [];
    this.listSampleWorkflowCount = 0;
    this.reset();
    makeObservable<SampleWorkflowStore, any>(this, {
      sampleWorkflow: observable,
      listSampleWorkflow: observable,
      listSampleWorkflowCount: observable,

      sampleWorkflowService: computed,
      fetchSampleWorkflow: action,
      updateSampleWorkflow: action,
      reset: action,
    });
  }

  get sampleWorkflowService() {
    return;
  }

  reset() {
    this.sampleWorkflow = new SampleWorkflow({});
    this.listSampleWorkflow = [];
    this.listSampleWorkflowCount = 0;
    this.sampleWorkflow = {
      ...this.sampleWorkflow,
      required: true,
      autoReceived: true,
      outsource: false,
      dateCreation: new Date(),
    };
  }

  fetchSampleWorkflow(page?, limit?) {}

  updateSampleWorkflow(sample: SampleWorkflow) {
    this.sampleWorkflow = sample;
  }
}
