import React, { useEffect, useState } from 'react';
import { Container } from 'reactstrap';
import { Form } from '@/library/components';
import { lookupItems, lookupValue } from '@/library/utils';
import { useStores } from '@/stores';

interface DiscountChargeEntry {
  code: string;
  amount: number;
  __typename?: string;
}

interface ModalDiscountChargeModifyProps {
  show?: boolean;
  arrValues?: DiscountChargeEntry;
  id?: string;
  onClick: (arrValues: DiscountChargeEntry, id: string) => void;
  onClose: () => void;
}

export const ModalDiscountChargeModify = (
  props: ModalDiscountChargeModifyProps,
) => {
  const { routerStore } = useStores();
  const [showModal, setShowModal] = useState<boolean | undefined>(props.show);
  const [values, setValues] = useState<DiscountChargeEntry>(
    props.arrValues || { code: '', amount: 0 },
  );

  useEffect(() => {
    setShowModal(props.show);
    setValues(props.arrValues || { code: '', amount: 0 });
  }, [props]);

  const handleAmountChange = (amount: number) => {
    setValues(prevValues => ({
      ...prevValues,
      amount,
    }));
  };

  const handleSelectChange = (code: string) => {
    setValues(prevValues => ({
      ...prevValues,
      code,
    }));
  };

  return (
    <Container>
      {showModal && (
        <>
          <div className='justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none'>
            <div className='relative w-auto my-6 mx-auto max-w-3xl'>
              <div className='border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none'>
                <div className='flex flex-col justify-between p-2 border-b border-solid border-gray-300 rounded-t'>
                  <h4 className='font-semibold text-lg'>
                    Update Discount Charges
                  </h4>
                </div>

                <div className='relative ml-24 mr-24 p-2 flex-auto'>
                  <div className='flex flex-row gap-2'>
                    <select
                      className={`leading-4 p-2 focus:outline-none focus:ring block w-full shadow-sm sm:text-base border-2 
                        border-gray-300 rounded-md`}
                      onChange={e => handleSelectChange(e.target.value)}
                      value={values.code}
                    >
                      <option value=''>Select</option>
                      {lookupItems(
                        routerStore.lookupItems,
                        'PATIENT VISIT - DISCOUNT_CHARGES',
                      ).map((item: any, index: number) => (
                        <option key={index} value={item.code}>
                          {lookupValue(item)}
                        </option>
                      ))}
                    </select>

                    <Form.Input
                      label=''
                      type='number'
                      placeholder='Amount'
                      value={values.amount}
                      onChange={e => handleAmountChange(e)}
                    />
                  </div>
                </div>

                <div className='flex items-center justify-end p-2 border-t border-solid border-gray-300 rounded-b'>
                  <button
                    className='text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1'
                    type='button'
                    style={{ transition: 'all .15s ease' }}
                    onClick={() => {
                      props.onClose();
                      setShowModal(false);
                    }}
                  >
                    Close
                  </button>
                  <button
                    className='bg-green-500 text-white active:bg-green-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1'
                    type='button'
                    style={{ transition: 'all .15s ease' }}
                    onClick={() => {
                      setShowModal(false);
                      props.onClick(values, props.id || '');
                    }}
                  >
                    Update
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className='opacity-25 fixed inset-0 z-40 bg-black'></div>
        </>
      )}
    </Container>
  );
};
