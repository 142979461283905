export class Route {
  _id: string;
  startLocation: string;
  pickupLocation: string;
  routeType: string;
  purpose: string;
  routeNo: string;
  description: string;
  round: number;
  fieldExecutive: string;
  startTime: string;
  endTime: string;
  actualTime: string;
  contact: string;
  dateCreation: Date;
  dateActive: Date;
  dateExpire: Date;
  enteredBy: string;
  companyCode: string;
  status: string;
  environment: string;
  dateOfEntry: Date;
  lastUpdated: Date;

  static types = {
    _id: 'string',
    startLocation: 'string',
    pickupLocation: 'string',
    routeType: 'string',
    purpose: 'string',
    routeNo: 'string',
    description: 'string',
    round: 'number',
    fieldExecutive: 'string',
    startTime: 'string',
    endTime: 'string',
    actualTime: 'string',
    contact: 'string',
    dateCreation: 'Date',
    dateActive: 'Date',
    dateExpire: 'Date',
    enteredBy: 'string',
    companyCode: 'string',
    status: 'string',
    environment: 'string',
    dateOfEntry: 'Date',
    lastUpdated: 'Date',
  };

  constructor(rawData: { [key in string]: any }) {
    this._id = rawData._id;
    this.startLocation = rawData.startLocation;
    this.pickupLocation = rawData.pickupLocation;
    this.routeType = rawData.routeType;
    this.purpose = rawData.purpose;
    this.routeNo = rawData.routeNo;
    this.description = rawData.description;
    this.round = rawData.round;
    this.fieldExecutive = rawData.fieldExecutive;
    this.startTime = rawData.startTime;
    this.endTime = rawData.endTime;
    this.actualTime = rawData.actualTime;
    this.contact = rawData.contact;
    this.dateCreation = rawData.dateCreation;
    this.dateActive = rawData.dateActive;
    this.dateExpire = rawData.dateExpire;
    this.enteredBy = rawData.enteredBy;
    this.companyCode = rawData.companyCode;
    this.status = rawData.status;
    this.environment = rawData.environment;
    this.dateOfEntry = rawData.dateOfEntry;
    this.lastUpdated = rawData.lastUpdated;
  }
}
