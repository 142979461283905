import { makeObservable, action, observable, computed } from 'mobx';
import { FeAllocation } from '../models';
import dayjs from 'dayjs';
// import { DeginisationService } from '../services';

export class FeAllocationStore {
  listFeAllocation!: FeAllocation[];
  listFeAllocationCount = 0;
  feAllocation!: FeAllocation;

  constructor() {
    this.listFeAllocation = [];
    this.feAllocation = new FeAllocation({});
    this.reset();
    makeObservable<FeAllocationStore, any>(this, {
      listFeAllocation: observable,
      listFeAllocationCount: observable,

      FeAllocationService: computed,
      fetchListFeAllocation: action,
      updateFeAllocation: action,
      reset: action,
    });
  }

  reset() {
    this.feAllocation = new FeAllocation({
      status: 'A',
      dateCreation: new Date(),
      dateActive: new Date(),
      dateExpire: new Date(
        dayjs(new Date()).add(365, 'days').format('YYYY-MM-DD'),
      ),
    });
    this.listFeAllocation = [];
    this.listFeAllocationCount = 0;
  }

  get FeAllocationService() {
    return;
  }

  fetchListFeAllocation(page?, limit?) {}

  updateFeAllocation = (location: FeAllocation) => {
    this.feAllocation = location;
  };
}
