export enum COLORS {
  PRIMARY = '#454CBF',
  SECONDARY = '#C57C85',
  ACCENT = '#422a68',
  BLACK = '#000000',
  GREY_0 = '#4A4A4A',
  GREY_1 = '#707070',
  LIGHT_1 = '#EbEbEb',
  WHITE = '#FFFFFF',
  BACKGROUND = '#FFFFFF',
  RED = '#FF0000',
  LIGHT_WHITE = '#F4F6F6',
  ORANGE = '#ffae1a',
  MONO = '#754C00',
}
