import { makeObservable, action, observable, computed } from 'mobx';
import { FieldWiseControl, SelectedItems } from '../models';
import { FieldWiseControlService } from '../services';

export class FieldWiseControlStore {
  fieldWiseControl!: FieldWiseControl;
  listFieldWiseControl!: FieldWiseControl[];
  listFieldWiseControlCount: number = 0;
  selectedItems!: SelectedItems;

  constructor() {
    this.fieldWiseControl = new FieldWiseControl({});
    this.selectedItems = new SelectedItems({});
    this.reset();
    makeObservable<FieldWiseControlStore, any>(this, {
      fieldWiseControl: observable,
      listFieldWiseControl: observable,
      listFieldWiseControlCount: observable,
      selectedItems: observable,

      fieldWiseControlService: computed,
      fetchListFieldWiseControl: action,
      updateFieldWiseControl: action,
      reset: action,
    });
  }

  reset() {
    this.fieldWiseControl = new FieldWiseControl({});
    this.listFieldWiseControl = [];
    this.listFieldWiseControlCount = 0;
    this.fieldWiseControl = {
      ...this.fieldWiseControl,
      status: 'A',
      createdAt: new Date(),
      version: 1,
      moduleName: [],
    };
  }

  get fieldWiseControlService() {
    return new FieldWiseControlService();
  }

  updateFieldWiseControlList(res: any) {
    if (!Array.isArray(res)) {
      if (!res.fieldWiseControl.success)
        return console.log(res.fieldWiseControl.message);
      this.listFieldWiseControl = res.fieldWiseControl.data;
      this.listFieldWiseControlCount = res.fieldWiseControl.paginatorInfo.count;
    } else {
      this.listFieldWiseControl = res;
    }
  }

  fetchListFieldWiseControl(page?, limit?) {}

  updateFieldWiseControl = (fieldWiseControl: FieldWiseControl) => {
    this.fieldWiseControl = fieldWiseControl;
  };
  updateSelectedItems(items: SelectedItems) {
    this.selectedItems = items;
  }
}
