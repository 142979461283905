// master
import { Banner as BannerModel } from '@features/master/banner/models';
import { Labs as LabModel } from '@features/master/labs/models';
import { Deginisation as DeginisationModel } from '@features/master/deginisation/models';
import { Department as DepartmentModel } from '@features/master/department/models';
import { MasterAnalyte as MasterAnalyteModel } from '@features/master/master-analyte/models';
import { TestAnalyteMapping as TestAnalyteMappingModel } from '@features/master/test-analyte-mapping/models';
import { MasterPanel as MasterPanelModel } from '@features/master/master-panel/models';
import { TestPanelMapping as TestPanelMappingModel } from '@features/master/test-panel-mapping/models';
import { MasterPackage as MasterPackageModel } from '@features/master/master-package/models';
import { TestMaster as TestMasterModel } from '@features/master/test-master/models';
import { Section as SectionModel } from '@features/master/section/models';
import { SampleContainer as SampleContainerModel } from '@features/master/sample-container/models';
import { SampleType as SampleTypeModel } from '@features/master/sample-type/models';
import { TestSampleMapping as TestSampleMappingModel } from '@features/master/test-sample-mapping/models';
import { Methods as MethodsModel } from '@features/master/methods/models';
import { Doctors as DoctorModel } from '@features/master/doctors/models';
import { RegistrationLocations as RegistrationLocationModel } from '@features/master/registration-locations/models';
import { CorporateClients as CorporateClientsModel } from '@features/master/corporate-clients/models';
import { DeliverySchedule as DeliveryScheduleModel } from '@features/master/delivery-schedule/models';
import { AdministrativeDivisions as AdministrativeDivisionsModel } from '@features/master/administrative-divisions/models';
import { SalesTeam as SalesTeamModel } from '@features/master/sales-team/models';
import { PossibleResults as PossibleResultsModel } from '@features/master/possible-results/models';
import { Library as LibraryModel } from '@features/master/library/models';
import { PriceList as PriceListModel } from '@features/master/price-list/models';
import { ReferenceRanges as ReferenceRangesModel } from '@features/master/reference-ranges/models';
import { CommentManager as CommentManagerModel } from '@features/master/comment-manager/models';
import { TatMaster as TatMasterModel } from '@features/master/tat-master/models';
import { HolidayMaster as HolidayMasterModel } from '@features/master/holiday-master/models';

// registration
import {
  PatientManger as PatientMangerModel,
  PatientVisit as PatientVisitModel,
  PatientOrder as PatientOrderModel,
  PatientTest as PatientTestModel,
  PatientResult as PatientResultModel,
  PatientSample as PatientSampleModel,
} from '@features/registration/patient-registration/models';
import { ClientRegistration as ClientRegistrationModel } from '@features/registration/client-registration/models';

// account receivable
import { TransactionHeader as TransactionHeaderModel } from '@features/account-receivable/transaction-details/models';
import { Payment as PaymentModel } from '@features/account-receivable/payment/models';
import { OpenBatch as OpenBatchModel } from '@features/account-receivable/open-batch/models';
import { Receipt as ReceiptModel } from '@features/account-receivable/receipt/models';
import { BillSummary as BillSummaryModel } from '@features/account-receivable/bill-summary/models';

// result entry
import { FilterGeneralResEntryList as FilterGeneralResEntryListModel } from '@features/result-entry/general-result-entry/models';
import { MicroResultEntryModel } from '@features/result-entry/micro-result-entry/models';
import { HistoResultEntryModel } from '@features/result-entry/histo-result-entry/models';

// validation
import { PendingPanelApproval as PendingPanelApprovalModel } from '@features/validation/panel-approval/models';

// patient reports
import { ReportDelivery as ReportDeliveryModel } from '@features/patient-reports/delivery-queue/models';

// communication
import { InterfaceManager as InterfaceManagerModel } from '@features/communication/interface-manager/models';
import { DataConversation as DataConversationModel } from '@features/communication/data-conversation/models';
import { HostCommunication as HostCommunicationModel } from '@features/communication/host-communication/models';
import { SegmentMapping as SegmentMappingModule } from '@features/communication/segment-mapping/models';
import { InstResultMapping as InstResultMappingModel } from '@features/communication/instrument-result-mapping/models';
import { TransmittedMessage as TransmittedMessageModel } from '@features/communication/transmitted-message/models';

// settings
import { Role as RoleModel } from '@features/settings/role/models';
import { Users as UserModel } from '@features/settings/users/models';
import { LoginActivity as LoginActivityModel } from '@features/settings/login-activity/models';
import { RoleMapping as RoleMappingModel } from '@features/settings/role-mapping/models';
import { ShortcutMenu as ShortcutMenuModel } from '@features/settings/shortcut-menu/models';
import { EnvironmentSettings as EnvironmentSettingsModel } from '@features/settings/environment/models';
import { NoticeBoard as NoticeBoardModel } from '@features/settings/notice-board/models';
import { Company as CompanyModel } from '@features/settings/company/models';
import { Lookup as LookupModel } from '@features/settings/lookup/models';

// report builder
import {
  PageLayout as PageLayoutModel,
  PageBranding as PageBrandingModel,
  ReportBody as ReportBodyModel,
  TemplatePatientResult as TemplatePatientResultModel,
} from '@features/report-builder/report-settings/models';

// enquiry
import { EventLogs as EventLogsModel } from '@features/enquiry/event-logs/models';

function getClassPropertiesWithTypes(classRef) {
  const propsWithTypes = {};
  if (classRef?.types) {
    for (const [key, type] of Object.entries(classRef?.types)) {
      propsWithTypes[key] = type;
    }
  }
  return propsWithTypes;
}

export const getFields = (screen: any) => {
  switch (screen) {
    // master
    case 'Collection-Banner':
      return getClassPropertiesWithTypes(BannerModel);
    case 'Collection-Lab':
      return getClassPropertiesWithTypes(LabModel);
    case 'Collection-Deginisation':
      return getClassPropertiesWithTypes(DeginisationModel);
    case 'Collection-Department':
      return getClassPropertiesWithTypes(DepartmentModel);
    case 'Collection-Analyte Master':
      return getClassPropertiesWithTypes(MasterAnalyteModel);
    case 'Collection-Test Analyte Mapping':
      return getClassPropertiesWithTypes(TestAnalyteMappingModel);
    case 'Collection-Panel Master':
      return getClassPropertiesWithTypes(MasterPanelModel);
    case 'Collection-Test Panel Mapping':
      return getClassPropertiesWithTypes(TestPanelMappingModel);
    case 'Collection-Package Master':
      return getClassPropertiesWithTypes(MasterPackageModel);
    case 'Collection-Test Master':
      return getClassPropertiesWithTypes(TestMasterModel);
    case 'Collection-Section':
      return getClassPropertiesWithTypes(SectionModel);
    case 'Collection-Sample Container':
      return getClassPropertiesWithTypes(SampleContainerModel);
    case 'Collection-Sample Type':
      return getClassPropertiesWithTypes(SampleTypeModel);
    case 'Collection-Test Sample Mapping':
      return getClassPropertiesWithTypes(TestSampleMappingModel);
    case 'Collection-Methods':
      return getClassPropertiesWithTypes(MethodsModel);
    case 'Collection-Doctors':
      return getClassPropertiesWithTypes(DoctorModel);
    case 'Collection-Registartion Locations':
      return getClassPropertiesWithTypes(RegistrationLocationModel);
    case 'Collection-Corporate Clients':
      return getClassPropertiesWithTypes(CorporateClientsModel);
    case 'Collection-Delivery Schedule':
      return getClassPropertiesWithTypes(DeliveryScheduleModel);
    case 'Collection-Administrative Divisions':
      return getClassPropertiesWithTypes(AdministrativeDivisionsModel);
    case 'Collection-Sales Team':
      return getClassPropertiesWithTypes(SalesTeamModel);
    case 'Collection-Possible Results':
      return getClassPropertiesWithTypes(PossibleResultsModel);
    case 'Collection-Library':
      return getClassPropertiesWithTypes(LibraryModel);
    case 'Collection-PriceList':
      return getClassPropertiesWithTypes(PriceListModel);
    case 'Collection-ReferenceRanges':
      return getClassPropertiesWithTypes(ReferenceRangesModel);
    case 'Collection-Comment Manager':
      return getClassPropertiesWithTypes(CommentManagerModel);
    case 'Collection-TAT Settings':
      return getClassPropertiesWithTypes(TatMasterModel);
    case 'Collection-Holiday Master':
      return getClassPropertiesWithTypes(HolidayMasterModel);

    // registration
    case 'Registration-Patient Registration-Patient Manager':
      return getClassPropertiesWithTypes(PatientMangerModel);
    case 'Registration-Patient Registration-Patient Visit':
      return getClassPropertiesWithTypes(PatientVisitModel);
    case 'Registration-Patient Registration-Patient Order':
      return getClassPropertiesWithTypes(PatientOrderModel);
    case 'Registration-Patient Registration-Patient Test':
      return getClassPropertiesWithTypes(PatientTestModel);
    case 'Registration-Patient Registration-Patient Result':
      return getClassPropertiesWithTypes(PatientResultModel);
    case 'Registration-Patient Registration-Patient Sample':
      return getClassPropertiesWithTypes(PatientSampleModel);
    case 'Registration-Client Registration':
      return getClassPropertiesWithTypes(ClientRegistrationModel);

    // account receivable
    case 'Account Receivable-Transaction Details':
      return getClassPropertiesWithTypes(TransactionHeaderModel);
    case 'Account Receivable-Payment':
      return getClassPropertiesWithTypes(PaymentModel);
    case 'Account Receivable-Open Batch':
      return getClassPropertiesWithTypes(OpenBatchModel);
    case 'Account Receivable-Receipt':
      return getClassPropertiesWithTypes(ReceiptModel);
    case 'Account Receivable-Bill Summary':
      return getClassPropertiesWithTypes(BillSummaryModel);

    // result entry
    case 'Result Entry-General Result Entry':
      return getClassPropertiesWithTypes(FilterGeneralResEntryListModel);
    case 'Result Entry-Micro Result Entry':
      return getClassPropertiesWithTypes(MicroResultEntryModel);
    case 'Result Entry-Histo Result Entry':
      return getClassPropertiesWithTypes(HistoResultEntryModel);

    // validation
    case 'Validation-Panel Approval':
      return getClassPropertiesWithTypes(PendingPanelApprovalModel);

    // patient reports
    case 'Patient Reports-Delivery Queue':
      return getClassPropertiesWithTypes(ReportDeliveryModel);

    // communication
    case 'Communication-Interface Manager':
      return getClassPropertiesWithTypes(InterfaceManagerModel);
    case 'Communication-Conversation Mapping':
      return getClassPropertiesWithTypes(DataConversationModel);
    case 'Communication-Host Communication':
      return getClassPropertiesWithTypes(HostCommunicationModel);
    case 'Communication-Data Segment Mapping':
      return getClassPropertiesWithTypes(SegmentMappingModule);
    case 'Communication-Instrument Result Mapping':
      return getClassPropertiesWithTypes(InstResultMappingModel);
    case 'Communication-Transmitted Message':
      return getClassPropertiesWithTypes(TransmittedMessageModel);

    // settings
    case 'Settings-Role':
      return getClassPropertiesWithTypes(RoleModel);
    case 'Settings-User':
      return getClassPropertiesWithTypes(UserModel);
    case 'Settings-Login Activity':
      return getClassPropertiesWithTypes(LoginActivityModel);
    case 'Settings-Role Mapping':
      return getClassPropertiesWithTypes(RoleMappingModel);
    case 'Settings-Shortcut Menu':
      return getClassPropertiesWithTypes(ShortcutMenuModel);
    case 'Settings-Environment':
      return getClassPropertiesWithTypes(EnvironmentSettingsModel);
    case 'Settings-Notice Boards':
      return getClassPropertiesWithTypes(NoticeBoardModel);
    case 'Settings-Company':
      return getClassPropertiesWithTypes(CompanyModel);
    case 'Settings-Lookup':
      return getClassPropertiesWithTypes(LookupModel);

    // report builder
    case 'Report Builder-Report Settings-Page Layout':
      return getClassPropertiesWithTypes(PageLayoutModel);
    case 'Report Builder-Report Settings-Page Branding':
      return getClassPropertiesWithTypes(PageBrandingModel);
    case 'Report Builder-Report Settings-Report Body':
      return getClassPropertiesWithTypes(ReportBodyModel);
    case 'Report Builder-Report Settings-Template Patient Result':
      return getClassPropertiesWithTypes(TemplatePatientResultModel);

    // enquiry
    case 'Enquiry-Event Log':
      return getClassPropertiesWithTypes(EventLogsModel);
    default:
      return {};
  }
};
