import { makeObservable, action, observable, computed } from 'mobx';
import { ContactInformation } from '../models';
import dayjs from 'dayjs';
// import { DeginisationService } from '../services';

export class ContactInformationStore {
  listContactInformation!: ContactInformation[];
  listContactInformationCount = 0;
  contactInformation!: ContactInformation;

  constructor() {
    this.listContactInformation = [];
    this.contactInformation = new ContactInformation({});
    this.reset();
    makeObservable<ContactInformationStore, any>(this, {
      listContactInformation: observable,
      listContactInformationCount: observable,

      ContactInformationService: computed,
      fetchListContactInformation: action,
      updateContactInformation: action,
      reset: action,
    });
  }

  reset() {
    this.contactInformation = new ContactInformation({
      status: 'A',
      dateCreation: new Date(),
      dateActive: new Date(),
      dateExpire: new Date(
        dayjs(new Date()).add(365, 'days').format('YYYY-MM-DD'),
      ),
    });
    this.listContactInformation = [];
    this.listContactInformationCount = 0;
  }

  get ContactInformationService() {
    return;
  }

  fetchListContactInformation(page?, limit?) {
    // this.DeginisationService.listDeginisation(page, limit);
  }

  updateContactInformation = (chart: ContactInformation) => {
    this.contactInformation = chart;
  };
}
