import async from '@/layouts/components/async.component';

const Phlebotomy = async(
  () => import('../phlebotomy/screens/phlebotomy.screen'),
);

export const sampleManagementRoutes = {
  path: '/sample-management',
  name: 'Sample Management',
  title: 'SAMPLE MANAGEMENT',
  order: 12,
  icon: 'TbTopologyStar3',
  children: [
    {
      path: '/sample-management/phlebotomy',
      name: 'Phlebotomy',
      icon: 'BiCommentDetail',
      component: Phlebotomy,
    },
  ],
};
