export class OpenBatch {
  _id: string;

  static types = {
    _id: 'string',
  };

  constructor(rawData: { [key in string]: any }) {
    this._id = rawData._id;
  }
}
