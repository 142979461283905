export class ImportFromFile {
  _id: string;
  transferType: string;
  isError: boolean;
  errorMsg: string;
  records: Array<any>;
  companyCode: string;
  environment: string;
  dateOfEntry: Date;
  lastUpdated: Date;

  static types = {
    _id: 'string',
    transferType: 'string',
    isError: 'boolean',
    errorMsg: 'string',
    records: 'Array',
    companyCode: 'string',
    environment: 'string',
    dateOfEntry: 'Date',
    lastUpdated: 'Date',
  };

  constructor(rawData: { [key in string]: any }) {
    this._id = rawData._id;
    this.transferType = rawData.transferType;
    this.isError = rawData.isError;
    this.errorMsg = rawData.errorMsg;
    this.records = rawData.records;
    this.companyCode = rawData.companyCode;
    this.environment = rawData.environment;
    this.dateOfEntry = rawData.dateOfEntry;
    this.lastUpdated = rawData.lastUpdated;
  }
}
