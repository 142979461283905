import { PriceList } from '@/models';
export class Labs {
  _id: string;
  existsVersionId: string;
  existsRecordId: string;
  code: string;
  name: string;
  country: string;
  state: string;
  district: string;
  city: string;
  area: string;
  postalCode: string;
  address: string;
  deliveryType: string;
  salesTerritory: string;
  labLicence: string;
  director: string;
  physician: string;
  mobileNo: string;
  contactNo: string;
  speciality: string;
  labType: string;
  defaultLab: string;
  openingTime: string;
  closingTime: string;
  email: string;
  web: string;
  registeredOffice: string;
  customerCare: string;
  corporateOffice: string;
  gst: string;
  sacCode: string;
  cinNo: string;
  labLog: any;
  image: string;
  autoRelease: boolean;
  requireReceveInLab: boolean;
  requireScainIn: boolean;
  routingDept: boolean;
  reportFormat: boolean;
  printLable: boolean;
  abnFlag: boolean;
  critical: boolean;
  fyiLine: string;
  workLine: string;
  priceList: Array<PriceList>;
  specificFormat: boolean;
  version: number;
  companyCode: string;
  status: string;
  environment: string;
  dateCreation: Date;
  dateActive: Date;
  dateExpire: Date;
  dateOfEntry: Date;
  lastUpdated: Date;

  static types = {
    _id: 'string',
    existsVersionId: 'string',
    existsRecordId: 'string',
    code: 'string',
    name: 'string',
    country: 'string',
    state: 'string',
    district: 'string',
    city: 'string',
    area: 'string',
    postalCode: 'string',
    address: 'string',
    deliveryType: 'string',
    salesTerritory: 'string',
    labLicence: 'string',
    director: 'string',
    physician: 'string',
    mobileNo: 'string',
    contactNo: 'string',
    speciality: 'string',
    labType: 'string',
    defaultLab: 'string',
    openingTime: 'string',
    closingTime: 'string',
    email: 'string',
    web: 'string',
    registeredOffice: 'string',
    customerCare: 'string',
    corporateOffice: 'string',
    gst: 'string',
    sacCode: 'string',
    cinNo: 'string',
    labLog: 'any',
    image: 'string',
    autoRelease: 'boolean',
    requireReceveInLab: 'boolean',
    requireScainIn: 'boolean',
    routingDept: 'boolean',
    reportFormat: 'boolean',
    printLable: 'boolean',
    abnFlag: 'boolean',
    critical: 'boolean',
    fyiLine: 'string',
    workLine: 'string',
    priceList: 'Array',
    specificFormat: 'boolean',
    version: 'number',
    companyCode: 'string',
    status: 'string',
    environment: 'string',
    dateCreation: 'Date',
    dateActive: 'Date',
    dateExpire: 'Date',
    dateOfEntry: 'Date',
    lastUpdated: 'Date',
  };

  constructor(rawData: { [key in string]: any }) {
    this._id = rawData._id;
    this.existsRecordId = rawData.existsRecordId;
    this.existsVersionId = rawData.existsVersionId;
    this.code = rawData.code;
    this.name = rawData.name;
    this.country = rawData.country;
    this.state = rawData.state;
    this.district = rawData.district;
    this.city = rawData.city;
    this.area = rawData.area;
    this.postalCode = rawData.postalCode;
    this.address = rawData.address;
    this.deliveryType = rawData.deliveryType;
    this.salesTerritory = rawData.salesTerritory;
    this.labLicence = rawData.labLicence;
    this.director = rawData.director;
    this.physician = rawData.physician;
    this.mobileNo = rawData.mobileNo;
    this.contactNo = rawData.contactNo;
    this.speciality = rawData.speciality;
    this.labType = rawData.labType;
    this.defaultLab = rawData.defaultLab;
    this.openingTime = rawData.openingTime;
    this.closingTime = rawData.closingTime;
    this.email = rawData.email;
    this.web = rawData.web;
    this.registeredOffice = rawData.registeredOffice;
    this.customerCare = rawData.customerCare;
    this.corporateOffice = rawData.corporateOffice;
    this.gst = rawData.gst;
    this.sacCode = rawData.sacCode;
    this.cinNo = rawData.cinNo;
    this.labLog = rawData.labLog;
    this.image = rawData.image;
    this.autoRelease = rawData.autoRelease;
    this.requireReceveInLab = rawData.requireReceveInLab;
    this.requireScainIn = rawData.requireScainIn;
    this.routingDept = rawData.routingDept;
    this.reportFormat = rawData.reportFormat;
    this.printLable = rawData.printLable;
    this.abnFlag = rawData.abnFlag;
    this.critical = rawData.critical;
    this.fyiLine = rawData.fyiLine;
    this.workLine = rawData.workLine;
    this.priceList = rawData.priceList;
    this.specificFormat = rawData.specificFormat;
    this.version = rawData.version;
    this.companyCode = rawData.companyCode;
    this.status = rawData.status;
    this.environment = rawData.environment;
    this.dateCreation = rawData.dateCreation;
    this.dateActive = rawData.dateActive;
    this.dateExpire = rawData.dateExpire;
    this.dateOfEntry = rawData.dateOfEntry;
    this.lastUpdated = rawData.lastUpdated;
  }
}

export class SelectedItems {
  country: any[];
  state: any[];
  district: any[];
  city: any[];
  area: any[];
  postalCode: any[];
  constructor(rawData: { [key in string]: any }) {
    this.country = rawData.country;
    this.state = rawData.state;
    this.district = rawData.district;
    this.city = rawData.city;
    this.area = rawData.area;
    this.postalCode = rawData.postalCode;
  }
}
