import React, { useEffect, useState } from 'react';
import { Container } from 'reactstrap';
import { Form } from '@/library/components';

interface ModalDesorptionModifyProps {
  show?: boolean;
  rowValue?: string;
  onClose: () => void;
}

export const ModalDescriptionModify = (props: ModalDesorptionModifyProps) => {
  const [showModal, setShowModal] = useState<boolean | undefined>(props.show);

  useEffect(() => {
    setShowModal(props.show);
  }, [props]);

  return (
    <Container>
      {showModal && (
        <>
          <div className='justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none'>
            <div className='relative w-auto my-6 mx-auto max-w-3xl'>
              <div className='border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none'>
                <div className='flex flex-col justify-between p-2 border-b border-solid border-gray-300 rounded-t'>
                  <h4 className='font-semibold text-lg'>View Result</h4>
                </div>
                <div className='relative p-2 flex-auto'>
                  <Form.MultilineInput1
                    rows={4}
                    label=''
                    disabled
                    placeholder='Result'
                    defaultValue={props.rowValue}
                  />
                </div>
                <div className='flex items-center justify-end p-2 border-t border-solid border-gray-300 rounded-b'>
                  <button
                    className='text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1'
                    type='button'
                    style={{ transition: 'all .15s ease' }}
                    onClick={() => {
                      props.onClose();
                      setShowModal(false);
                    }}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className='opacity-25 fixed inset-0 z-40 bg-black'></div>
        </>
      )}
    </Container>
  );
};
