import { makeObservable, action, observable, computed } from 'mobx';
import { FieldExecutive } from '../models';
import dayjs from 'dayjs';
// import { DeginisationService } from '../services';

export class FieldExecutiveStore {
  listFieldExecutive!: FieldExecutive[];
  listFieldExecutiveCount = 0;
  fieldExecutive!: FieldExecutive;

  constructor() {
    this.listFieldExecutive = [];
    this.fieldExecutive = new FieldExecutive({});
    this.reset();
    makeObservable<FieldExecutiveStore, any>(this, {
      listFieldExecutive: observable,
      listFieldExecutiveCount: observable,

      FieldExecutiveService: computed,
      fetchListFieldExecutive: action,
      updateFieldExecutive: action,
      reset: action,
    });
  }

  reset() {
    this.fieldExecutive = new FieldExecutive({
      status: 'A',
      dateCreation: new Date(),
      dateActive: new Date(),
      dateExpire: new Date(
        dayjs(new Date()).add(365, 'days').format('YYYY-MM-DD'),
      ),
    });
    this.listFieldExecutive = [];
    this.listFieldExecutiveCount = 0;
  }

  get FieldExecutiveService() {
    return;
  }

  fetchListFieldExecutive(page?, limit?) {
    // this.DeginisationService.listDeginisation(page, limit);
  }

  updateFieldExecutive = (item: FieldExecutive) => {
    this.fieldExecutive = item;
  };
}
