export class LoginActivity {
  _id: string;
  user: object;
  systemInfo: object;
  sessionAllowedCount: number; // Added sessionAllowedCount
  companyCode: string; // Added companyCode
  environment: string; // Added environment
  dateOfEntry: Date;
  lastUpdated: Date;

  static types = {
    _id: 'string',
    user: 'object',
    systemInfo: 'object',
    sessionAllowedCount: 'number', // Added sessionAllowedCount
    companyCode: 'string', // Added companyCode
    environment: 'string', // Added environment
    dateOfEntry: 'Date',
    lastUpdated: 'Date',
  };

  constructor(rawData: { [key in string]: any }) {
    this._id = rawData._id;
    this.user = rawData.user;
    this.systemInfo = rawData.systemInfo;
    this.sessionAllowedCount = rawData.sessionAllowedCount; // Added sessionAllowedCount
    this.companyCode = rawData.companyCode; // Added companyCode
    this.environment = rawData.environment; // Added environment
    this.dateOfEntry = rawData.dateOfEntry;
    this.lastUpdated = rawData.lastUpdated;
  }
}
