export class PatientVisit {
  _id: string; // Added _id
  pId: number;
  patientName: string;
  rLab: string;
  visitId: string;
  labId: number | string;
  visitDate: Date;
  registrationDate: Date;
  collectionDate: Date;
  dueDate: Date;
  birthDate: Date;
  age: number;
  ageUnits: string;
  sex: string;
  collectionCenter: string;
  collectionCenterName: string;
  corporateCode: string;
  corporateName: string;
  isPredefinedPanel: boolean;
  isEmployeeCode?: boolean;
  acClass: string;
  grossAmount: number;
  netAmount: number;
  discountAmount: number;
  discountPer: number;
  isNewDoctor: boolean;
  doctorId: string;
  doctorName: string;
  doctorMobileNo: string;
  miscellaneousCharges: number;
  miscCharges: Array<any>;
  discountCharges: object;
  reportPriority: string;
  deliveryMode: Array<any>;
  reportTo: Array<any>;
  history: boolean;
  holdReport: boolean;
  holdReason: string;
  resultDate: Date; // Added resultDate
  abnFlag: boolean; // Added abnFlag
  isPrintPrimaryBarcod: boolean;
  specificFormat: boolean;
  status: string;
  extraData: {
    accountType: string;
    additionalInfo: string;
    invoiceAc: string;
    billingMethod: string;
    billNumber: string;
    urgent: boolean;
    confidental: boolean;
    autoNumber: string;
    methodCollection: string;
    collectedBy: string;
    pendingDataEntry: boolean;
    receivedDate: Date;
    resultDate: Date;
    approvalDate: Date;
    approvalStatus: string;
    reportStatus: string;
    reportedDate: Date;
    enteredBy: string;
    gestation: string;
    height: string;
    weight: string;
    archieve: string;
    loginInterface: string;
    registrationInterface: string;
    submittedSystem: string;
    submittedOn: string;
    externalLabId: string;
    employeeCode: string;
    environment: string;
  };
  companyCode: string; // Added companyCode
  mobileNo: string;
  email: string;
  openingTime: string;
  closingTime: string;
  environment: string; // Added environment
  documentType: string;
  dateOfEntry: Date;
  lastUpdated: Date;

  static types = {
    _id: 'string',
    pId: 'number',
    patientName: 'string',
    rLab: 'string',
    visitId: 'string',
    labId: 'number',
    visitDate: 'Date',
    registrationDate: 'Date',
    collectionDate: 'Date',
    dueDate: 'Date',
    birthDate: 'Date',
    age: 'number',
    ageUnits: 'string',
    sex: 'string',
    collectionCenter: 'string',
    collectionCenterName: 'string',
    corporateCode: 'string',
    corporateName: 'string',
    isPredefinedPanel: 'boolean',
    isEmployeeCode: 'boolean',
    acClass: 'string',
    grossAmount: 'number',
    netAmount: 'number',
    discountAmount: 'number',
    discountPer: 'number',
    isNewDoctor: 'boolean',
    doctorId: 'string',
    doctorName: 'string',
    doctorMobileNo: 'string',
    miscellaneousCharges: 'number',
    miscCharges: 'Array',
    discountCharges: 'object',
    reportPriority: 'string',
    deliveryMode: 'Array',
    reportTo: 'Array',
    history: 'boolean',
    holdReport: 'boolean',
    holdReason: 'string',
    resultDate: 'Date',
    abnFlag: 'boolean',
    isPrintPrimaryBarcod: 'boolean',
    specificFormat: 'boolean',
    status: 'string',
    extraData: 'object',
    companyCode: 'string',
    mobileNo: 'string',
    email: 'string',
    openingTime: 'string',
    closingTime: 'string',
    environment: 'string',
    documentType: 'string',
    dateOfEntry: 'Date',
    lastUpdated: 'Date',
  };

  constructor(rawData: { [key in string]: any }) {
    this._id = rawData._id;
    this.pId = rawData.pId;
    this.patientName = rawData.patientName;
    this.rLab = rawData.rLab;
    this.visitId = rawData.visitId;
    this.labId = rawData.labId;
    this.visitDate = rawData.visitDate;
    this.registrationDate = rawData.registrationDate;
    this.collectionDate = rawData.collectionDate;
    this.dueDate = rawData.dueDate;
    this.birthDate = rawData.birthDate;
    this.age = rawData.age;
    this.ageUnits = rawData.ageUnits;
    this.sex = rawData.sex;
    this.collectionCenter = rawData.collectionCenter;
    this.collectionCenterName = rawData.collectionCenterName;
    this.corporateCode = rawData.corporateCode;
    this.corporateName = rawData.corporateName;
    this.isPredefinedPanel = rawData.isPredefinedPanel;
    this.isEmployeeCode = rawData.isEmployeeCode;
    this.acClass = rawData.acClass;
    this.grossAmount = rawData.grossAmount;
    this.netAmount = rawData.netAmount;
    this.discountAmount = rawData.discountAmount;
    this.discountPer = rawData.discountPer;
    this.isNewDoctor = rawData.isNewDoctor;
    this.doctorId = rawData.doctorId;
    this.doctorName = rawData.doctorName;
    this.doctorMobileNo = rawData.doctorMobileNo;
    this.miscellaneousCharges = rawData.miscellaneousCharges;
    this.miscCharges = rawData.miscCharges;
    this.discountCharges = rawData.discountCharges;
    this.reportPriority = rawData.reportPriority;
    this.deliveryMode = rawData.deliveryMode;
    this.reportTo = rawData.reportTo;
    this.history = rawData.history;
    this.holdReport = rawData.holdReport;
    this.holdReason = rawData.holdReason;
    this.resultDate = rawData.resultDate;
    this.abnFlag = rawData.abnFlag;
    this.isPrintPrimaryBarcod = rawData.isPrintPrimaryBarcod;
    this.specificFormat = rawData.specificFormat;
    this.status = rawData.status;
    this.extraData = rawData.extraData;
    this.companyCode = rawData.companyCode;
    this.mobileNo = rawData.mobileNo;
    this.email = rawData.email;
    this.openingTime = rawData.openingTime;
    this.closingTime = rawData.closingTime;
    this.environment = rawData.environment;
    this.documentType = rawData.documentType;
    this.dateOfEntry = rawData.dateOfEntry;
    this.lastUpdated = rawData.lastUpdated;
  }
}

export class SelectedPatientVisitItems {
  miscCharges: any[];
  constructor(rawData: { [key in string]: any }) {
    this.miscCharges = rawData.miscCharges;
  }
}
