export class FilterGeneralResEntryList {
  pLab: string;
  departement: string | undefined;
  testStatus: string;
  resultStatus: string;
  testCode: string | undefined;
  analyteCode: string;
  labId: number | string | undefined;
  name: string;
  finishResult: string;
  _id: string;
  pId: number;
  patientOrderId: string;
  dob: Date;
  patientMobileNo: string;
  doctorId: string;
  doctorMobileNo: number;
  registrationLocation: string;
  contactNo: string;
  history: boolean;
  historyDetails: string;
  department: string;
  departmentName: string;
  patientTestId: string;
  panelCode: string;
  panelName: string;
  reportTemplate: string;
  panelDescription: string;
  panelMethodCode: string;
  panelMethodName: string;
  testName: string;
  testMethodName: string;
  analyteName: string;
  analyteDesc: string;
  analyteMethodName: string;
  reportable: boolean;
  resultType: string;
  calculationFlag: boolean;
  calculationVariable: string;
  calculationFormula: string;
  calcName: string;
  picture: string;
  result: string;
  isResultEditor: boolean;
  isAutoUpdate: boolean;
  units: string;
  resultDate: Date;
  alpha: string;
  numeric: string;
  instResult: string;
  instUnit: string;
  instResultDate: Date;
  isAlpha: boolean;
  critical: boolean;
  rangeVersion: string;
  showRanges: boolean;
  rangeSetOn: string;
  rangeId: string;
  sex: string;
  age: number;
  ageUnit: string;
  species: string;
  loNor: string;
  refRangeAlpha: string;
  interpretation: string;
  resultNote: string;
  conclusion: string;
  instId: string;
  instType: string;
  approvalStatus: string;
  rangeType: string;
  reRangesList: any[];
  colorScheme: any;
  extraData: any;
  resultOrder: number;
  reportOrder: number;
  registrationDate: Date;
  dueDate: Date;
  specificFormat: boolean;
  panelApprovalInput: any;
  isAutoSubmit: boolean;
  isHoldQC: boolean;
  isAutoApproval: boolean;
  isPMHoldQC: boolean;
  isResultUpdate: boolean;
  validationLevel: string;
  companyCode: string;
  environment: string;
  documentType: string;
  enteredBy: string;
  dateOfEntry: Date;
  lastUpdated: Date;

  static types = {
    pLab: 'string',
    departement: 'string',
    testStatus: 'string',
    resultStatus: 'string',
    testCode: 'string',
    analyteCode: 'string',
    labId: 'number',
    name: 'string',
    finishResult: 'string',
    _id: 'string',
    pId: 'number',
    patientOrderId: 'string',
    dob: 'Date',
    patientMobileNo: 'string',
    doctorId: 'string',
    doctorMobileNo: 'number',
    registrationLocation: 'string',
    contactNo: 'string',
    history: 'boolean',
    historyDetails: 'string',
    department: 'string',
    departmentName: 'string',
    patientTestId: 'string',
    panelCode: 'string',
    panelName: 'string',
    reportTemplate: 'string',
    panelDescription: 'string',
    panelMethodCode: 'string',
    panelMethodName: 'string',
    testName: 'string',
    testMethodName: 'string',
    analyteName: 'string',
    analyteDesc: 'string',
    analyteMethodName: 'string',
    reportable: 'boolean',
    resultType: 'string',
    calculationFlag: 'boolean',
    calculationVariable: 'string',
    calculationFormula: 'string',
    calcName: 'string',
    picture: 'string',
    result: 'string',
    isResultEditor: 'boolean',
    isAutoUpdate: 'boolean',
    units: 'string',
    resultDate: 'Date',
    alpha: 'string',
    numeric: 'string',
    instResult: 'string',
    instUnit: 'string',
    instResultDate: 'Date',
    isAlpha: 'boolean',
    critical: 'boolean',
    rangeVersion: 'string',
    showRanges: 'boolean',
    rangeSetOn: 'string',
    rangeId: 'string',
    sex: 'string',
    age: 'number',
    ageUnit: 'string',
    species: 'string',
    loNor: 'string',
    refRangeAlpha: 'string',
    interpretation: 'string',
    resultNote: 'string',
    conclusion: 'string',
    instId: 'string',
    instType: 'string',
    approvalStatus: 'string',
    rangeType: 'string',
    reRangesList: 'Array',
    colorScheme: 'any',
    extraData: 'any',
    resultOrder: 'number',
    reportOrder: 'number',
    registrationDate: 'Date',
    dueDate: 'Date',
    specificFormat: 'boolean',
    panelApprovalInput: 'any',
    isAutoSubmit: 'boolean',
    isHoldQC: 'boolean',
    isAutoApproval: 'boolean',
    isPMHoldQC: 'boolean',
    isResultUpdate: 'boolean',
    validationLevel: 'string',
    companyCode: 'string',
    environment: 'string',
    documentType: 'string',
    enteredBy: 'string',
    dateOfEntry: 'Date',
    lastUpdated: 'Date',
  };

  constructor(rawData: { [key in string]: any }) {
    this.pLab = rawData.pLab;
    this.departement = rawData.departement;
    this.testStatus = rawData.testStatus;
    this.resultStatus = rawData.resultStatus;
    this.testCode = rawData.testCode;
    this.analyteCode = rawData.analyteCode;
    this.labId = rawData.labId;
    this.name = rawData.name;
    this.finishResult = rawData.finishResult;
    this._id = rawData._id;
    this.pId = rawData.pId;
    this.patientOrderId = rawData.patientOrderId;
    this.pLab = rawData.pLab;
    this.labId = rawData.labId;
    this.name = rawData.name;
    this.dob = rawData.dob;
    this.patientMobileNo = rawData.patientMobileNo;
    this.doctorId = rawData.doctorId;
    this.doctorMobileNo = rawData.doctorMobileNo;
    this.registrationLocation = rawData.registrationLocation;
    this.contactNo = rawData.contactNo;
    this.history = rawData.history;
    this.historyDetails = rawData.historyDetails;
    this.department = rawData.department;
    this.departmentName = rawData.departmentName;
    this.patientTestId = rawData.patientTestId;
    this.panelCode = rawData.panelCode;
    this.panelName = rawData.panelName;
    this.reportTemplate = rawData.reportTemplate;
    this.panelDescription = rawData.panelDescription;
    this.panelMethodCode = rawData.panelMethodCode;
    this.panelMethodName = rawData.panelMethodName;
    this.testCode = rawData.testCode;
    this.testName = rawData.testName;
    this.testMethodName = rawData.testMethodName;
    this.analyteCode = rawData.analyteCode;
    this.analyteName = rawData.analyteName;
    this.analyteDesc = rawData.analyteDesc;
    this.analyteMethodName = rawData.analyteMethodName;
    this.reportable = rawData.reportable;
    this.resultType = rawData.resultType;
    this.calculationFlag = rawData.calculationFlag;
    this.calculationVariable = rawData.calculationVariable;
    this.calculationFormula = rawData.calculationFormula;
    this.calcName = rawData.calcName;
    this.picture = rawData.picture;
    this.result = rawData.result;
    this.isResultEditor = rawData.isResultEditor;
    this.isAutoUpdate = rawData.isAutoUpdate;
    this.units = rawData.units;
    this.resultDate = rawData.resultDate;
    this.alpha = rawData.alpha;
    this.numeric = rawData.numeric;
    this.instResult = rawData.instResult;
    this.instUnit = rawData.instUnit;
    this.instResultDate = rawData.instResultDate;
    this.isAlpha = rawData.isAlpha;
    this.critical = rawData.critical;
    this.rangeVersion = rawData.rangeVersion;
    this.showRanges = rawData.showRanges;
    this.rangeSetOn = rawData.rangeSetOn;
    this.rangeId = rawData.rangeId;
    this.sex = rawData.sex;
    this.age = rawData.age;
    this.ageUnit = rawData.ageUnit;
    this.species = rawData.species;
    this.loNor = rawData.loNor;
    this.refRangeAlpha = rawData.refRangeAlpha;
    this.interpretation = rawData.interpretation;
    this.resultNote = rawData.resultNote;
    this.conclusion = rawData.conclusion;
    this.instId = rawData.instId;
    this.instType = rawData.instType;
    this.approvalStatus = rawData.approvalStatus;
    this.resultStatus = rawData.resultStatus;
    this.rangeType = rawData.rangeType;
    this.reRangesList = rawData.reRangesList;
    this.colorScheme = rawData.colorScheme;
    this.extraData = rawData.extraData;
    this.resultOrder = rawData.resultOrder;
    this.reportOrder = rawData.reportOrder;
    this.registrationDate = rawData.registrationDate;
    this.dueDate = rawData.dueDate;
    this.specificFormat = rawData.specificFormat;
    this.finishResult = rawData.finishResult;
    this.panelApprovalInput = rawData.panelApprovalInput;
    this.isAutoSubmit = rawData.isAutoSubmit;
    this.isHoldQC = rawData.isHoldQC;
    this.isAutoApproval = rawData.isAutoApproval;
    this.isPMHoldQC = rawData.isPMHoldQC;
    this.isResultUpdate = rawData.isResultUpdate;
    this.validationLevel = rawData.validationLevel;
    this.companyCode = rawData.companyCode;
    this.environment = rawData.environment;
    this.documentType = rawData.documentType;
    this.enteredBy = rawData.enteredBy;
    this.dateOfEntry = rawData.dateOfEntry;
    this.lastUpdated = rawData.lastUpdated;
  }
}

export class SelectedItems {
  library: any[];
  constructor(rawData: { [key in string]: any }) {
    this.library = rawData.library;
  }
}
