import React, { useState } from 'react';
import { observer } from 'mobx-react';
import {
  TableBootstrap,
  textFilter,
  Form,
  Icons,
  Tooltip,
  List,
  Buttons,
  Grid,
  Svg,
  sortCaret,
} from '@/library/components';
import { Confirm } from '@/library/models';
import { lookupItems, lookupValue } from '@/library/utils';
import { ModalFieldsModify } from './modal-fileds-modify';

let interfaceType;
let instrumentType;
let instrumentName;
let dataFlowFrom;
let protocol;
let block;
let fileds;
let environment;
let companyCode;

interface InterfaceManagerListProps {
  data: any;
  extraData: any;
  totalSize: number;
  isView?: boolean;
  isDelete?: boolean;
  isUpdate?: boolean;
  isExport?: boolean;
  onDelete?: (selectedItem: Confirm) => void;
  onSelectedRow?: (selectedItem: any) => void;
  onUpdateItem?: (value: any, dataField: string, id: string) => void;
  onPageSizeChange?: (page: number, totalSize: number) => void;
  onFilter?: (
    type: string,
    filter: any,
    page: number,
    totalSize: number,
  ) => void;
}

export const InterfaceManagerList = observer(
  (props: InterfaceManagerListProps) => {
    const [modalField, setModalFiled] = useState({});
    const [widthFeildBox, setWidthFieldBox] = useState('30px');
    const [selectedRowId, setSelectedRowId] = useState('');
    return (
      <>
        <div className={`${props.isView ? 'shown' : 'hidden'}`}>
          <TableBootstrap
            id='_id'
            data={props.data}
            totalSize={props.totalSize}
            columns={[
              {
                dataField: '_id',
                text: 'Id',
                hidden: true,
                csvExport: false,
              },
              {
                dataField: 'interfaceType',
                text: 'Interface Type',
                headerClasses: 'textHeader4',
                sort: true,
                headerStyle: {
                  fontSize: 0,
                },
                sortCaret: (order, column) => sortCaret(order, column),
                csvFormatter: col => (col ? col : ''),
                filter: textFilter({
                  placeholder: 'Interface Type',
                  getFilter: filter => {
                    interfaceType = filter;
                  },
                }),
                editorRenderer: (
                  editorProps,
                  value,
                  row,
                  column,
                  rowIndex,
                  columnIndex,
                ) => (
                  <>
                    <select
                      value={row.interfaceType}
                      className='leading-4 p-2 focus:ring-indigo-500 ocus:border-indigo-500 block w-full shadow-sm sm:text-base border-2 rounded-md'
                      onChange={e => {
                        const interfaceType = e.target.value;
                        props.onUpdateItem &&
                          props.onUpdateItem(
                            interfaceType,
                            column.dataField,
                            row._id,
                          );
                      }}
                    >
                      <option>Select</option>
                      {lookupItems(
                        props.extraData.lookupItems,
                        'INTERFACE_TYPE',
                      ).map((item: any, index: number) => (
                        <option key={index} value={item.code}>
                          {lookupValue(item)}
                        </option>
                      ))}
                    </select>
                  </>
                ),
              },
              {
                dataField: 'instrumentType',
                text: 'Inst Type',
                headerClasses: 'textHeader4',
                sort: true,
                headerStyle: {
                  fontSize: 0,
                },
                editorStyle: { textTransform: 'uppercase' },
                style: { textTransform: 'uppercase' },
                sortCaret: (order, column) => sortCaret(order, column),
                csvFormatter: col => (col ? col : ''),
                filter: textFilter({
                  placeholder: 'Inst Type',
                  getFilter: filter => {
                    instrumentType = filter;
                  },
                }),
                editorRenderer: (
                  editorProps,
                  value,
                  row,
                  column,
                  rowIndex,
                  columnIndex,
                ) => (
                  <>
                    <Form.Input
                      name='instrumentType'
                      placeholder='Instrument Type'
                      style={{ textTransform: 'uppercase' }}
                      // value={row.instrumentType}
                      onBlur={instrumentTypeValue => {
                        const instrumentType =
                          instrumentTypeValue?.toUpperCase();
                        if (
                          row.instrumentType !== instrumentType &&
                          instrumentType
                        ) {
                          props.onUpdateItem &&
                            props.onUpdateItem(
                              instrumentType,
                              column.dataField,
                              row._id,
                            );
                        }
                      }}
                    />
                  </>
                ),
              },
              {
                dataField: 'instrumentName',
                text: 'Inst Name',
                headerClasses: 'textHeader5',
                sort: true,
                headerStyle: {
                  fontSize: 0,
                },
                editorStyle: { textTransform: 'uppercase' },
                style: { textTransform: 'uppercase' },
                sortCaret: (order, column) => sortCaret(order, column),
                csvFormatter: col => (col ? col : ''),
                filter: textFilter({
                  placeholder: 'Inst Name',
                  getFilter: filter => {
                    instrumentName = filter;
                  },
                }),
                editorRenderer: (
                  editorProps,
                  value,
                  row,
                  column,
                  rowIndex,
                  columnIndex,
                ) => (
                  <>
                    <Form.Input
                      name='instrumentType'
                      placeholder='Instrument Type'
                      style={{ textTransform: 'uppercase' }}
                      onBlur={instrumentType => {
                        if (
                          row.instrumentType !== instrumentType &&
                          instrumentType
                        ) {
                          props.onUpdateItem &&
                            props.onUpdateItem(
                              instrumentType,
                              column.dataField,
                              row._id,
                            );
                        }
                      }}
                    />
                  </>
                ),
              },
              {
                dataField: 'protocol',
                text: 'Protocol',
                headerClasses: 'textHeader6',
                sort: true,
                headerStyle: {
                  fontSize: 0,
                },
                sortCaret: (order, column) => sortCaret(order, column),
                csvFormatter: col => (col ? col : ''),
                filter: textFilter({
                  placeholder: 'Protocol',
                  getFilter: filter => {
                    protocol = filter;
                  },
                }),
                editorRenderer: (
                  editorProps,
                  value,
                  row,
                  column,
                  rowIndex,
                  columnIndex,
                ) => (
                  <>
                    <select
                      value={row.environment}
                      className='leading-4 p-2 focus:ring-indigo-500 ocus:border-indigo-500 block w-full shadow-sm sm:text-base border-2 rounded-md'
                      onChange={e => {
                        const protocol = e.target.value;
                        props.onUpdateItem &&
                          props.onUpdateItem(
                            protocol,
                            column.dataField,
                            row._id,
                          );
                      }}
                    >
                      <option>Select</option>
                      {lookupItems(props.extraData.lookupItems, 'PROTOCOL').map(
                        (item: any, index: number) => (
                          <option key={index} value={item.code}>
                            {lookupValue(item)}
                          </option>
                        ),
                      )}
                    </select>
                  </>
                ),
              },
              {
                dataField: 'blockStart',
                text: 'Block Start',
                headerClasses: 'textHeader5',
                sort: true,
                headerStyle: {
                  fontSize: 0,
                },
                sortCaret: (order, column) => sortCaret(order, column),
                csvFormatter: col => (col ? col : ''),
                filter: textFilter({
                  placeholder: 'Block Start',
                  getFilter: filter => {
                    block = filter;
                  },
                }),
                formatter: (cellContent, row) => (
                  <>
                    <List space={2} direction='row' justify='center'>
                      <div>
                        <div className='mb-2'>
                          <Buttons.Button
                            size='medium'
                            type='solid'
                            onClick={() => {}}
                          >
                            {`Start:${
                              row.blockStart && row.blockStart !== undefined
                                ? row.blockStart
                                    .toString()
                                    .replaceAll(/&amp;/g, '&')
                                    .replaceAll(/&gt;/g, '>')
                                    .replaceAll(/&lt;/g, '<')
                                    .replaceAll(/&quot;/g, '"')
                                    .replaceAll(/â/g, '’')
                                    .replaceAll(/â¦/g, '…')
                                    .toString()
                                : undefined
                            }`}
                          </Buttons.Button>
                        </div>
                      </div>
                    </List>
                  </>
                ),
                editorRenderer: (
                  editorProps,
                  value,
                  row,
                  column,
                  rowIndex,
                  columnIndex,
                ) => (
                  <>
                    <Grid cols={2}>
                      <Form.Input
                        name='startBlock'
                        placeholder='Start Block'
                        onBlur={(blockStart: string | undefined) => {
                          if (row.blockStart !== blockStart && blockStart) {
                            blockStart =
                              blockStart !== undefined
                                ? blockStart
                                    .replaceAll('&', '&amp;')
                                    .replaceAll('>', '&gt;')
                                    .replaceAll('<', '&lt;')
                                    .replaceAll('"', '&quot;')
                                    .replaceAll('’', 'â')
                                    .replaceAll('…', 'â¦')
                                    .toString()
                                : undefined;

                            props.onUpdateItem &&
                              props.onUpdateItem(
                                blockStart,
                                'blockStart',
                                row._id,
                              );
                          }
                        }}
                      />
                    </Grid>
                  </>
                ),
              },
              {
                dataField: 'blockEnd',
                text: 'Block End',
                headerClasses: 'textHeader5',
                sort: true,
                headerStyle: {
                  fontSize: 0,
                },
                sortCaret: (order, column) => sortCaret(order, column),
                csvFormatter: col => (col ? col : ''),
                filter: textFilter({
                  placeholder: 'Block End',
                  getFilter: filter => {
                    block = filter;
                  },
                }),
                formatter: (cellContent, row) => (
                  <>
                    <List space={2} direction='row' justify='center'>
                      <div>
                        <div className='mb-2'>
                          <Buttons.Button
                            size='medium'
                            type='solid'
                            onClick={() => {}}
                          >
                            {`End:${
                              row.blockEnd && row.blockEnd !== undefined
                                ? row.blockEnd
                                    .toString()
                                    .replaceAll(/&amp;/g, '&')
                                    .replaceAll(/&gt;/g, '>')
                                    .replaceAll(/&lt;/g, '<')
                                    .replaceAll(/&quot;/g, '"')
                                    .replaceAll(/â/g, '’')
                                    .replaceAll(/â¦/g, '…')
                                    .toString()
                                : undefined
                            }`}
                          </Buttons.Button>
                        </div>
                      </div>
                    </List>
                  </>
                ),
                editorRenderer: (
                  editorProps,
                  value,
                  row,
                  column,
                  rowIndex,
                  columnIndex,
                ) => (
                  <>
                    <Grid cols={2}>
                      <Form.Input
                        name='endBlock'
                        placeholder='End Block'
                        onBlur={(blockEnd: string | undefined) => {
                          if (row.blockEnd !== blockEnd && blockEnd) {
                            blockEnd =
                              blockEnd !== undefined
                                ? blockEnd
                                    .replaceAll('&', '&amp;')
                                    .replaceAll('>', '&gt;')
                                    .replaceAll('<', '&lt;')
                                    .replaceAll('"', '&quot;')
                                    .replaceAll('’', 'â')
                                    .replaceAll('…', 'â¦')
                                    .toString()
                                : undefined;
                            props.onUpdateItem &&
                              props.onUpdateItem(blockEnd, 'blockEnd', row._id);
                          }
                        }}
                      />
                    </Grid>
                  </>
                ),
              },
              {
                dataField: 'fileds',
                text: 'Fileds',
                headerClasses: 'textHeader1',
                sort: true,
                headerStyle: {
                  fontSize: 0,
                },
                editable: false,
                sortCaret: (order, column) => sortCaret(order, column),
                csvFormatter: (cell, row, rowIndex) =>
                  `Filed:${row.fileds.map(
                    item => item.filed,
                  )},Value${row.fileds.map(item => item.value)}`,
                filter: textFilter({
                  placeholder: 'Fileds',
                  getFilter: filter => {
                    fileds = filter;
                  },
                }),
                style: { width: widthFeildBox },
                formatter: (cellContent, row) => (
                  <>
                    <div>
                      <Tooltip
                        tooltipText={
                          row._id != selectedRowId ? 'Expand' : 'Collapse'
                        }
                      >
                        <Icons.IconContext
                          color='#000000'
                          size='20'
                          onClick={() => {
                            if (row._id === selectedRowId) {
                              setSelectedRowId('');
                              setWidthFieldBox('30px');
                            } else {
                              setSelectedRowId(row._id);
                              setWidthFieldBox('200px');
                            }
                          }}
                        >
                          {Icons.getIconTag(
                            row._id != selectedRowId
                              ? Icons.IconBi.BiExpand
                              : Icons.IconBi.BiCollapse,
                          )}
                        </Icons.IconContext>
                      </Tooltip>
                    </div>

                    {row._id == selectedRowId && (
                      <div
                        className='flex-wrap gap-2 flex flex-row w-100 items-center overflow-auto'
                        style={{ width: widthFeildBox }}
                      >
                        <Tooltip tooltipText='Edit'>
                          <Icons.IconContext
                            color='#000000'
                            size='20'
                            onClick={() => {
                              setModalFiled({
                                show: true,
                                rowData: row?.fileds,
                                id: row?._id,
                              });
                            }}
                          >
                            {Icons.getIconTag(Icons.IconBi.BiEdit)}
                          </Icons.IconContext>
                          {row.fileds?.map((item, index) => (
                            <div className='mb-2' key={index}>
                              <Buttons.Button
                                key={index}
                                size='medium'
                                type='solid'
                                onClick={() => {}}
                              >
                                {`Filed:${item.filed} - Value:${
                                  item.value !== undefined
                                    ? item.value
                                        .toString()
                                        .replaceAll(/&amp;/g, '&')
                                        .replaceAll(/&gt;/g, '>')
                                        .replaceAll(/&lt;/g, '<')
                                        .replaceAll(/&quot;/g, '"')
                                        .replaceAll(/â/g, '’')
                                        .replaceAll(/â¦/g, '…')
                                        .toString()
                                    : undefined
                                }`}
                              </Buttons.Button>
                            </div>
                          ))}
                        </Tooltip>
                      </div>
                    )}
                  </>
                ),
              },
              {
                text: 'Company Code',
                dataField: 'companyCode',
                sort: true,
                headerStyle: {
                  fontSize: 0,
                },
                sortCaret: (order, column) => sortCaret(order, column),
                editable: false,
                csvFormatter: col => (col ? col : ''),
                filter: textFilter({
                  placeholder: 'Company Code',
                  getFilter: filter => {
                    companyCode = filter;
                  },
                }),
                headerClasses: 'textHeader2',
                // editorRenderer: (
                //   editorProps,
                //   value,
                //   row,
                //   column,
                //   rowIndex,
                //   columnIndex,
                // ) => (
                //   <>
                //     <AutoCompleteCompanyList
                //       isLabel={false}
                //       hasError={false}
                //       onSelect={companyCode => {
                //         props.onUpdateItem &&
                //           props.onUpdateItem(
                //             companyCode,
                //             column.dataField,
                //             row._id,
                //           );
                //       }}
                //     />
                //   </>
                // ),
              },
              {
                dataField: 'environment',
                text: 'Environment',
                headerClasses: 'textHeader3',
                sort: true,
                headerStyle: {
                  fontSize: 0,
                },
                editable: false,
                sortCaret: (order, column) => sortCaret(order, column),
                csvFormatter: col => (col ? col : ''),
                filter: textFilter({
                  placeholder: 'Environment',
                  getFilter: filter => {
                    environment = filter;
                  },
                }),
                // editorRenderer: (
                //   editorProps,
                //   value,
                //   row,
                //   column,
                //   rowIndex,
                //   columnIndex,
                // ) => (
                //   <>
                //     <select
                //       value={row.environment}
                //       className='leading-4 p-2 focus:ring-indigo-500 ocus:border-indigo-500 block w-full shadow-sm sm:text-base border-2 rounded-md'
                //       onChange={e => {
                //         const environment = e.target.value;
                //         props.onUpdateItem &&
                //           props.onUpdateItem(
                //             environment,
                //             column.dataField,
                //             row._id,
                //           );
                //       }}
                //     >
                //      <option>Select</option>
                //       {lookupItems(props.extraData.lookupItems, 'ENVIRONMENT').map(
                //         (item: any, index: number) => (
                //           <option key={index} value={item.code}>
                //             {lookupValue(item)}
                //           </option>
                //         ),
                //       )}
                //     </select>
                //   </>
                // ),
              },
              {
                dataField: 'operation',
                text: 'Action',
                editable: false,
                csvExport: false,
                // hidden: !props.isDelete,
                formatter: (cellContent, row) => (
                  <>
                    <div className='flex flex-row'>
                      {props.isDelete && (
                        <Tooltip tooltipText='Delete'>
                          <Icons.IconContext
                            color='#ffffff'
                            size='20'
                            onClick={() =>
                              props.onDelete &&
                              props.onDelete({
                                type: 'Delete',
                                show: true,
                                id: [row._id],
                                title: 'Are you sure?',
                                body: 'Do you want to delete this record?',
                              })
                            }
                          >
                            {Icons.getIconTag(Icons.IconBs.BsFillTrashFill)}
                          </Icons.IconContext>
                        </Tooltip>
                      )}
                    </div>
                  </>
                ),
                headerClasses: 'sticky right-0  bg-gray-500 text-white z-50',
                classes: (cell, row, rowIndex, colIndex) => {
                  return 'sticky right-0 bg-gray-500';
                },
                style: (cell, row, rowIndex, colIndex) => {
                  return {
                    zIndex: props.data?.length - rowIndex,
                  };
                },
              },
            ]}
            isDelete={props.isDelete}
            isEditModify={props.isUpdate}
            isExport={props.isExport}
            isSelectRow={true}
            fileName='Interface Manager'
            onSelectedRow={rows => {
              props.onSelectedRow &&
                props.onSelectedRow(rows.map((item: any) => item._id));
            }}
            onUpdateItem={(value: any, dataField: string, id: string) => {
              props.onUpdateItem && props.onUpdateItem(value, dataField, id);
            }}
            onPageSizeChange={(page, size) => {
              props.onPageSizeChange && props.onPageSizeChange(page, size);
            }}
            onFilter={(type, filter, page, size) => {
              props.onFilter && props.onFilter(type, filter, page, size);
            }}
            clearAllFilter={() => {
              interfaceType('');
              instrumentName('');
              instrumentType('');
              dataFlowFrom('');
              protocol('');
              block('');
              fileds('');
              environment('');
            }}
            dynamicStylingFields={[
              'interfaceType',
              'instrumentType',
              'instrumentName',
              'startBlock',
              'endBlock',
              'environment',
            ]}
            hideExcelSheet={['_id', 'operation']}
          />
        </div>
        <ModalFieldsModify
          {...modalField}
          onClick={(values, id) => {
            setModalFiled({ show: false });
            props.onUpdateItem && props.onUpdateItem(values, 'fileds', id);
          }}
          onClose={() => {
            setModalFiled({ show: false });
          }}
        />
      </>
    );
  },
);
