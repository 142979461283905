export class PatientSample {
  _id: string;
  pId: number;
  specimenId: number;
  patientOrderId: string;
  labId: number;
  pLab: string;
  outSourceLab: string;
  outSourceStatus: string;
  departmentList: string;
  containerId: string;
  sampleType: string;
  testList: string;
  receivedDate: Date;
  collectionDate: Date;
  methodCollection: string;
  primaryContainer: boolean; // Changed to boolean
  aliquot: boolean; // Changed to boolean
  uniqueContainer: boolean; // Changed to boolean
  labSpecific: boolean; // Changed to boolean
  departmentSpecific: boolean; // Changed to boolean
  sharedSample: boolean; // Changed to boolean
  labelInstructions: string;
  externalSampleId: string;
  comment: string;
  enteredBy: string;
  companyCode: string;
  status: string;
  environment: string;
  documentType: string;
  dateOfEntry: Date;
  lastUpdated: Date;

  static types = {
    _id: 'string',
    pId: 'number',
    specimenId: 'number',
    patientOrderId: 'string',
    labId: 'number',
    pLab: 'string',
    outSourceLab: 'string',
    outSourceStatus: 'string',
    departmentList: 'string',
    containerId: 'string',
    sampleType: 'string',
    testList: 'string',
    receivedDate: 'Date',
    collectionDate: 'Date',
    methodCollection: 'string',
    primaryContainer: 'boolean',
    aliquot: 'boolean',
    uniqueContainer: 'boolean',
    labSpecific: 'boolean',
    departmentSpecific: 'boolean',
    sharedSample: 'boolean',
    labelInstructions: 'string',
    externalSampleId: 'string',
    comment: 'string',
    enteredBy: 'string',
    companyCode: 'string',
    status: 'string',
    environment: 'string',
    documentType: 'string',
    dateOfEntry: 'Date',
    lastUpdated: 'Date',
  };

  constructor(rawData: { [key in string]: any }) {
    this._id = rawData._id;
    this.pId = rawData.pId;
    this.specimenId = rawData.specimenId;
    this.patientOrderId = rawData.patientOrderId;
    this.labId = rawData.labId;
    this.pLab = rawData.pLab;
    this.outSourceLab = rawData.outSourceLab;
    this.outSourceStatus = rawData.outSourceStatus;
    this.departmentList = rawData.departmentList;
    this.containerId = rawData.containerId;
    this.sampleType = rawData.sampleType;
    this.testList = rawData.testList;
    this.receivedDate = rawData.receivedDate;
    this.collectionDate = rawData.collectionDate;
    this.methodCollection = rawData.methodCollection;
    this.primaryContainer = rawData.primaryContainer;
    this.aliquot = rawData.aliquot;
    this.uniqueContainer = rawData.uniqueContainer;
    this.labSpecific = rawData.labSpecific;
    this.departmentSpecific = rawData.departmentSpecific;
    this.sharedSample = rawData.sharedSample;
    this.labelInstructions = rawData.labelInstructions;
    this.externalSampleId = rawData.externalSampleId;
    this.comment = rawData.comment;
    this.enteredBy = rawData.enteredBy;
    this.companyCode = rawData.companyCode;
    this.status = rawData.status;
    this.environment = rawData.environment;
    this.documentType = rawData.documentType;
    this.dateOfEntry = rawData.dateOfEntry;
    this.lastUpdated = rawData.lastUpdated;
  }
}
